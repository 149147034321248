import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const drivers = `/${service}/drivers`;

export function getDriversStatus(nodeID) {
  const url = `${drivers}/status?nodeID=${nodeID}`;
  return {
    method: GET,
    url,
  };
}

export function getDriversMpg(nodeID, span) {
  const url = `${drivers}/mpg?nodeID=${nodeID}&span=${span || 'day'}`;
  return {
    method: GET,
    url,
  };
}

export function getDriversLate(nodeID, span) {
  const url = `${drivers}/late?nodeID=${nodeID}&span=${span || 'day'}`;
  return {
    method: GET,
    url,
  };
}
