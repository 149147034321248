import { networkAction } from 'helpers/network/networkAction';

import {
  GET_TASKS_STATUS,
  GET_TIME_OFF_REQUESTS,
  GET_EMPLOYEE_REVIEWS,
  GET_DRIVER_ACCESSORIALS,
  GET_TIME_SHEETS,
  GET_DRIVER_OF_THE_MONTH,
  GET_DRIVER_SAFETY_BONUS,
  GET_SUPERVISOR_BONUS,
  GET_EMPLOYEE_ACCESSORIALS,
} from 'constants/tasks';

import * as Api from 'api';

export const getTasksStatus = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_TASKS_STATUS,
  Api.getTasksStatus,
  [supervisorID],
);

export const getTimeOffRequests = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_TIME_OFF_REQUESTS,
  Api.getTimeOffRequests,
  [supervisorID],
);

export const getDriverSafetyBonus = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_DRIVER_SAFETY_BONUS,
  Api.getDriverSafetyBonus,
  [supervisorID],
);

export const getSupervisorBonus = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_SUPERVISOR_BONUS,
  Api.getSupervisorBonus,
  [supervisorID],
);

export const getTimeSheets = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_TIME_SHEETS,
  Api.getTimeSheets,
  [supervisorID],
);

export const getEmployeeReviews = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_EMPLOYEE_REVIEWS,
  Api.getEmployeeReviews,
  [supervisorID],
);

export const getDriverAccessorials = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_DRIVER_ACCESSORIALS,
  Api.getDriverAccessorials,
  [supervisorID],
);

export const getEmployeeAccessorials = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_EMPLOYEE_ACCESSORIALS,
  Api.getEmployeeAccessorials,
  [supervisorID],
);

export const getDriverOfTheMonth = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_DRIVER_OF_THE_MONTH,
  Api.getDriverOfTheMonth,
  [supervisorID],
);
