// routes
import {
  DRIVETIME,
  EMPLOYEES,
  DRIVERS,
  TASKS,
  WAREHOUSES,
  APPOINTMENTS,
  CUSTOMERS,
  HEALTH,
  SALES,
} from 'constants/routes';

import LocalShipping from '@material-ui/icons/LocalShippingTwoTone';
import Timelapse from '@material-ui/icons/TimelapseTwoTone';
import People from '@material-ui/icons/PeopleTwoTone';
import Assignment from '@material-ui/icons/AssignmentTwoTone';
import LocalConvenienceStore from '@material-ui/icons/LocalConvenienceStoreTwoTone';
import Event from '@material-ui/icons/EventTwoTone';
import Face from '@material-ui/icons/FaceTwoTone';
import SignalCellularAlt from '@material-ui/icons/SignalCellularAltTwoTone';
import AttachMoney from '@material-ui/icons/AttachMoneyTwoTone';

export const menu = [
  {
    icon: Assignment,
    label: 'Tasks',
    path: TASKS,
    divider: true,
  },
  {
    icon: People,
    label: 'Employees',
    path: EMPLOYEES,
    divider: true,
  },
  {
    icon: LocalConvenienceStore,
    label: 'Warehouses',
    path: WAREHOUSES,
    divider: true,
  },
  {
    icon: LocalShipping,
    label: 'Drivers',
    path: DRIVERS,
    divider: true,
  },
  {
    icon: Event,
    label: 'Appointments',
    path: APPOINTMENTS,
    divider: true,
  },
  {
    icon: Timelapse,
    label: 'Drive Time',
    path: DRIVETIME,
    divider: true,
  },
  {
    icon: Face,
    label: 'Customers',
    path: CUSTOMERS,
    divider: true,
  },
  {
    icon: SignalCellularAlt,
    label: 'Health Status',
    path: HEALTH,
    divider: true,
  },
  {
    icon: AttachMoney,
    label: 'Sales',
    path: SALES,
    divider: true,
  },
];
