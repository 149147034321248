export default theme => ({
  appGrid: {
    height: 47,
  },
  AppBarGreetings: {
    color: 'white',
  },
  white: {
    color: 'white',
  },
});
