import amber from '@material-ui/core/colors/amber';
import * as the from 'themes/theme';
import buttonStyle from 'assets/jss/material-kit-react/components/buttonStyle';

import {
  REACT_APP_ROOT_BACKGROUND,
} from 'config/env';

const drawerWidth = 240;
export default theme => ({
  background:
    theme.palette.type === 'light'
      ? {
        backgroundColor: theme.palette.primary.light,
      }
      : {
        backgroundColor: theme.palette.background.default,
      },
  list: {
    paddingTop: 0,
  },
  warning: {
    backgroundColor: amber[700],
  },
  AppBarGreetings: {
    color: 'white',
    padding: 12,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  drawerContainer: {
    height: '100vh',
    background: theme.palette.background.paper,
  },
  flex: {
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${REACT_APP_ROOT_BACKGROUND}) no-repeat`,
    backgroundSize: 'cover',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    background: theme.palette.background.paper,
    height: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  test: {
    background: 'blue',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  content: {
    flexGrow: 1,
  },
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: '#3C4858',
    fontSize: '18px',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  selected: {
    color: `${the.default.primary[500]} !important`,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 200,
  },
  floatingActionButton: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  ...buttonStyle,
});
