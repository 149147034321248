import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';

// components
import WorkspacesList from 'components/WorkspacesList';
import DialogWorkspaceUsers from 'components/DialogWorkspaceUsers';
import Loading from 'components/Loading';
import FormWorkspace from 'components/FormWorkspace';

// styles
import styles from './styles';

class WrapperWorkspacePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    workspaces: PropTypes.array,
    users: PropTypes.array,
    createWorkspace: PropTypes.func,
    deleteWorkspace: PropTypes.func,
    updateWorkspace: PropTypes.func,
    getWorkspaceUsers: PropTypes.func,
    addWorkspaceUser: PropTypes.func,
    deleteWorkspaceUser: PropTypes.func,
    setCurrentWorkspace: PropTypes.func,
    setWorkspaceToDefault: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedWorkspace: undefined,
      formWorkspaceOpen: false,
      workspaceUsersOpen: false,
    };
  }

  onWorkspaceUsers(selectedWorkspace) {
    this.setState({ selectedWorkspace });
    this.openWorkspaceUsers();
  }

  async onSetCurrentWorkspace(workspace) {
    const { setWorkspaceToDefault, refresh, setCurrentWorkspace } = this.props;
    setCurrentWorkspace(workspace);
    await setWorkspaceToDefault(workspace.id, { default: true });
    return refresh();
  }

  getStepContent() {
    const { index } = this.state;
    const { workspaces, history } = this.props;

    switch (index) {
      case 0:
        return (
          <WorkspacesList
            history={history}
            workspaces={workspaces}
            onSelect={selectedWorkspace => this.setState({
              selectedWorkspace,
              formWorkspaceOpen: true,
            })}
            onWorkspaceUsers={this.onWorkspaceUsers.bind(this)}
            onSetCurrentWorkspace={this.onSetCurrentWorkspace.bind(this)}
          />
        );
      default:
    }

    return [];
  }

  openWorkspaceUsers() {
    this.setState({ workspaceUsersOpen: true });
  }

  closeWorkspaceUsers() {
    this.setState({ workspaceUsersOpen: false });
  }

  render() {
    const {
      classes,
      history,
      loading,
      createWorkspace,
      deleteWorkspace,
      updateWorkspace,
      refresh,
      getWorkspaceUsers,
      addWorkspaceUser,
      deleteWorkspaceUser,
      users,
    } = this.props;

    const {
      index,
      selectedWorkspace,
      formWorkspaceOpen,
      workspaceUsersOpen,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {
          loading
            ? (
              <Loading
                message={'Fetching all Workspaces'}
              />
            )
            : (
              <div>
                <Helmet>
                  <title>Workspaces</title>
                  <meta name="MyAccount" content="MyAccount page" />
                </Helmet>
                <AppBar position="absolute" className={classes.appBar}>
                  <Tabs
                    value={index}
                    onChange={(e, v) => {
                      this.setState({
                        index: v,
                      });
                      history.push(`?index=${v}`);
                    }}

                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="auto"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      label="All"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                  </Tabs>
                </AppBar>
                <div className="container withAppBarAndTabs">
                  {
                    this.getStepContent()
                  }
                </div>
              </div>
            )
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formWorkspaceOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormWorkspace
          open={formWorkspaceOpen}
          workspace={selectedWorkspace}
          close={() => {
            this.setState({
              formWorkspaceOpen: false,
            });
            setTimeout(() => this.setState({
              selectedWorkspace: undefined,
            }), 200);
          }
          }
          createWorkspace={createWorkspace}
          deleteWorkspace={deleteWorkspace}
          updateWorkspace={updateWorkspace}
          refresh={refresh}
        />
        <DialogWorkspaceUsers
          open={workspaceUsersOpen}
          close={() => {
            this.closeWorkspaceUsers();
            setTimeout(() => this.setState({
              selectedWorkspace: undefined,
            }), 200);
          }}

          workspace={selectedWorkspace}
          getWorkspaceUsers={getWorkspaceUsers}
          addWorkspaceUser={addWorkspaceUser}
          deleteWorkspaceUser={deleteWorkspaceUser}
          users={users}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperWorkspacePage);
