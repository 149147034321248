import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SUPERVISORS,
  GET_SUPERVISOR_BY_ID,
  DELETE_SUPERVISOR,
  UPDATE_SUPERVISOR,
  CREATE_SUPERVISOR,
} from 'constants/supervisors';

import * as Api from 'api';

export const getSupervisors = () => async dispatch => networkAction(
  dispatch,
  GET_SUPERVISORS,
  Api.getSupervisors,
  [],
);

export const getSupervisorById = id => async dispatch => networkAction(
  dispatch,
  GET_SUPERVISOR_BY_ID,
  Api.getSupervisorById,
  [id],
);

export const deleteSupervisor = id => async dispatch => networkAction(
  dispatch,
  DELETE_SUPERVISOR,
  Api.deleteSupervisor,
  [id],
);

export const createSupervisor = body => async dispatch => networkAction(
  dispatch,
  CREATE_SUPERVISOR,
  Api.createSupervisor,
  [body],
);

export const updateSupervisor = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SUPERVISOR,
  Api.updateSupervisor,
  [id, body],
);
