import { networkAction } from 'helpers/network/networkAction';

import {
  GET_WAREHOUSES,
  GET_WAREHOUSE_STATUS,
  GET_WAREHOUSE_CARGO_CONSOLIDATION,
  GET_WAREHOUSE_CARGO_CONSOLIDATION_GRAPH,
} from 'constants/warehouses';

import * as Api from 'api';

export const getWarehouses = () => async dispatch => networkAction(
  dispatch,
  GET_WAREHOUSES,
  Api.getWarehouses,
  [],
);

export const getWarehousesStatus = nodeID => async dispatch => networkAction(
  dispatch,
  GET_WAREHOUSE_STATUS,
  Api.getWarehousesStatus,
  [nodeID],
);

export const getWarehouseCargoConsolidation = nodeID => async dispatch => networkAction(
  dispatch,
  GET_WAREHOUSE_CARGO_CONSOLIDATION,
  Api.getWarehouseCargoConsolidation,
  [nodeID],
);

export const getWarehouseCargoConsolidationGraph = () => async dispatch => networkAction(
  dispatch,
  GET_WAREHOUSE_CARGO_CONSOLIDATION_GRAPH,
  Api.getWarehouseCargoConsolidationGraph,
  [],
);
