import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';

// styles
import styles from './styles';

class WrapperDataStudioPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <Typography variant="title">Item One</Typography>
        );
      case 1:
        return (
          <Typography variant="title">Item Two</Typography>
        );
      default:
        return (
          <Typography variant="title">You have gone too far, Sonny Jim !</Typography>
        );
    }
  }

  render() {
    const {
      classes,
      history,
    } = this.props;

    const {
      index,
    } = this.state;

    return (
      <div className="page">
        <Helmet>
          <title>DataStudio</title>
          <meta name="DataStudio" content="DataStudio page" />
        </Helmet>
        <div className="root">
          <AppBar position="absolute" color="default">
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(`?index=${v}`);
              }}

              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: 'tabsRoot', indicator: 'tabsIndicator' }}
            >
              <Tab
                label="Revenues by Week"
                classes={{
                  root: 'tabRoot',
                  selected: 'tabSelected',
                }}
              />
              <Tab
                label="Custom Report"
                classes={{
                  root: 'tabRoot',
                  selected: 'tabSelected',
                }}
              />
            </Tabs>
          </AppBar>
          <div className="container withAppBarAndTabs" style={{ background: '#E1E0D5' }}>
            <div style={{ maxWidth: 1200, height: '100%', margin: 'auto' }}>
              <iframe
                width="100%" 
                height="100%" 
                src="https://datastudio.google.com/embed/reporting/1r1IhYlF0ufn_R5TFbmhkzXIXX78zefBg/page/dnjMB" 
                frameborder="0" 
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperDataStudioPage);
