export const serviceRoles = {
  account: {
    name: 'account',
    roles: {
      any: 'any',
      anon: 'anon',
      user: 'user',
      admin: 'admin',
      superadmin: 'superadmin',
    },
  },
  reports: {
    name: 'reports',
    roles: {
      any: 'any',
      anon: 'anon',
      reporter: 'reporter',
    },
  },
};
