import { networkAction } from 'helpers/network/networkAction';

import {
  GET_HEALTH_SERVICES,
  GET_HEALTH_STATUS,
} from 'constants/health';

import * as Api from 'api';

export const getHealthStatus = () => async dispatch => networkAction(
  dispatch,
  GET_HEALTH_STATUS,
  Api.getHealthStatus,
  [],
);

export const getHealthServices = () => async dispatch => networkAction(
  dispatch,
  GET_HEALTH_SERVICES,
  Api.getHealthServices,
  [],
);
