import CheckCircle from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';
import Error from '@material-ui/icons/Error';
import Critical from '@material-ui/icons/NotificationImportant';
import Help from '@material-ui/icons/Help';

function getColorShade() {
  const colors = {
    green: '#4caf50',
    orange: '#ff9800',
    red: '#f44336',
    black: '#000000',
  };

  return colors;
}

export function getThresholdIcon(thresholds, trigger, value) {
  const thresholdsCount = thresholds.length;

  if ((trigger === 'gt' && (value <= thresholds[0]))
    || (trigger === 'lt' && (value >= thresholds[0]))) {
    return CheckCircle;
  }

  if (thresholdsCount === 1) {
    if ((trigger === 'gt') && (value > thresholds[0])) {
      return Error;
    }

    if ((trigger === 'lt') && (value < thresholds[0])) {
      return Error;
    }
  }

  if (thresholdsCount === 2) {
    if ((trigger === 'gt') && (value > thresholds[1])) {
      return Error;
    }

    if ((trigger === 'lt') && (value < thresholds[1])) {
      return Error;
    }

    if ((trigger === 'gt') && (value > thresholds[0])) {
      return Warning;
    }

    if ((trigger === 'lt') && (value < thresholds[0])) {
      return Warning;
    }
  }

  if (thresholdsCount === 3) {
    if ((trigger === 'gt') && (value > thresholds[2])) {
      return Critical;
    }

    if ((trigger === 'lt') && (value < thresholds[2])) {
      return Critical;
    }

    if ((trigger === 'gt') && (value > thresholds[1])) {
      return Error;
    }

    if ((trigger === 'lt') && (value < thresholds[1])) {
      return Error;
    }

    if ((trigger === 'gt') && (value > thresholds[0])) {
      return Warning;
    }

    if ((trigger === 'lt') && (value < thresholds[0])) {
      return Warning;
    }
  }

  return Help;
}

export function getThresholdColor(thresholds, trigger, value) {
  if ((thresholds.length > 3) || thresholds.length === 0) return '#9e9e9e';
  const colorsShade = getColorShade();
  const colors = [colorsShade.green, colorsShade.orange, colorsShade.red, colorsShade.black];
  if (thresholds.length === 2) colors.splice(-1, 1);
  if (thresholds.length === 1) { colors.splice(1, 1); colors.splice(-1, 1); }
  const sortedThreshold = thresholds.sort();
  let reversed = false;
  if (trigger === 'lt') { colors.reverse(); sortedThreshold.reverse(); reversed = true; }

  // console.log(sortedThreshold, colors);
  for (const key in sortedThreshold) {
    if (sortedThreshold.hasOwnProperty(key)) {
      const threshold = sortedThreshold[key];
      if (reversed ? value < threshold : value <= threshold) {
        // console.log(`%c${value}, ${trigger}, ${threshold}, ${key}`, `color: ${colors[key]}`);
        return colors[key];
      }
    }
  }
  // console.log(`%c${value}, ${trigger}, _`, `color: ${colors[colors.length - 1]}`);
  return colors[colors.length - 1];
}

export function getBackgroundGradient(thresholds, trigger, value, min, max) {
  if ((thresholds.length > 3) || thresholds.length === 0) return '#9e9e9e';
  const colorsShade = getColorShade();
  const colors = [colorsShade.green, colorsShade.orange, colorsShade.red, colorsShade.black];
  if (thresholds.length === 2) colors.splice(-1, 1);
  if (thresholds.length === 1) { colors.splice(1, 1); colors.splice(-1, 1); }
  const sortedThreshold = thresholds.sort();
  if (trigger === 'lt') { colors.reverse(); sortedThreshold.reverse(); }


  let gradient = `linear-gradient(to right, ${colors[0]} ${sortedThreshold[0] - min}%`;
  for (const key in sortedThreshold) {
    if (sortedThreshold.hasOwnProperty(key)) {
      const threshold = sortedThreshold[key];
      const isLast = Number(key) === sortedThreshold.length - 1;
      if (!isLast) {
        gradient = `${gradient}, ${colors[Number(key) + 1]} ${threshold * 100 / max}%`;
      } else {
        gradient = `${gradient}, ${colors[colors.length - 1]} 100%)`;
      }
    }
  }
  return gradient;
}

export function getColorDependingOnBackground(bgColor) {
  if (!bgColor) { return ''; }

  return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
}

export function formattedPrice(x) {
  let neg = false;
  if (x < 0) {
    neg = true;
  }

  const price = Math.abs(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (neg) {
    return `-$${(price)}`;
  }

  return `$${(price)}`;
}
