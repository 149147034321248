import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import WrapperTasksPage from '../component/WrapperTasksPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getTimeOffRequests: (...args) => dispatch(Actions.getTimeOffRequests(...args)),
  getDriverSafetyBonus: (...args) => dispatch(Actions.getDriverSafetyBonus(...args)),
  getSupervisorBonus: (...args) => dispatch(Actions.getSupervisorBonus(...args)),
  getTimeSheets: (...args) => dispatch(Actions.getTimeSheets(...args)),
  getEmployeeReviews: (...args) => dispatch(Actions.getEmployeeReviews(...args)),
  getDriverAccessorials: (...args) => dispatch(Actions.getDriverAccessorials(...args)),
  getEmployeeAccessorials: (...args) => dispatch(Actions.getEmployeeAccessorials(...args)),
  getDriverOfTheMonth: (...args) => dispatch(Actions.getDriverOfTheMonth(...args)),
});
class TasksPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getTimeOffRequests: PropTypes.func,
    getDriverSafetyBonus: PropTypes.func,
    getSupervisorBonus: PropTypes.func,
    getTimeSheets: PropTypes.func,
    getEmployeeReviews: PropTypes.func,
    getDriverAccessorials: PropTypes.func,
    getEmployeeAccessorials: PropTypes.func,
    getDriverOfTheMonth: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentSupervisor) this.refresh([1, 2, 3, 4, 5, 6, 7, 8]);
  }

  componentWillReceiveProps(nextProps) {
    const nextSupervisor = nextProps.app.currentSupervisor;
    const currentSupervisor = this.props.app.currentSupervisor;
    if ((currentSupervisor === undefined) && (nextSupervisor !== undefined)) {
      setTimeout(() => this.refresh([1, 2, 3, 4, 5, 6, 7, 8]), 500);
    } else if (currentSupervisor && nextSupervisor && (currentSupervisor.id !== nextSupervisor.id)) {
      setTimeout(() => this.refresh([1, 2, 3, 4, 5, 6, 7, 8]), 500);
    }
  }

  initReport() {
    return (
      [
        {
          name: 'Time Off',
        },
        {
          name: 'Safety Bonus',
        },
        {
          name: 'Superv. Bonus',
        },
        {
          name: 'Time Sheets',
        },
        {
          name: 'Reviews',
        },
        {
          name: 'Accessorials',
        },
        {
          name: 'Accessorials',
        },
        {
          name: 'Driver Month',
        },
      ]
    );
  }

  refresh(key) {
    const {
      getTimeOffRequests,
      getDriverSafetyBonus,
      getSupervisorBonus,
      getTimeSheets,
      getEmployeeReviews,
      getDriverAccessorials,
      getEmployeeAccessorials,
      getDriverOfTheMonth,
      app,
    } = this.props;

    const { currentSupervisor } = app;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getTimeOffRequests(currentSupervisor.id).then(r => this.resolve(0, r));
    }
    if (key.find(k => k === 2)) {
      getDriverSafetyBonus(currentSupervisor.id).then(r => this.resolve(1, r));
    }
    if (key.find(k => k === 3)) {
      getSupervisorBonus(currentSupervisor.id).then(r => this.resolve(2, r));
    }
    if (key.find(k => k === 4)) {
      getTimeSheets(currentSupervisor.id).then(r => this.resolve(3, r));
    }
    if (key.find(k => k === 5)) {
      getEmployeeReviews(currentSupervisor.id).then(r => this.resolve(4, r));
    }
    if (key.find(k => k === 6)) {
      getDriverAccessorials(currentSupervisor.id).then(r => this.resolve(5, r));
    }
    if (key.find(k => k === 7)) {
      getEmployeeAccessorials(currentSupervisor.id).then(r => this.resolve(6, r));
    }
    if (key.find(k => k === 8)) {
      getDriverOfTheMonth(currentSupervisor.id).then(r => this.resolve(7, r));
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperTasksPage
        history={history}
        location={location}
        reports={reports}
        key={app.currentSupervisor ? app.currentSupervisor.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TasksPage);
