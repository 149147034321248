export const ROOT = '/';
export const SIGNIN = '/signin';
export const DEMO = '/demo';
export const DEBUG = '/debug';
export const DEFAULT = '/default';
export const MY_ACCOUNT = '/my-account';
export const WORKSPACE = '/workspaces';
export const DRIVETIME = '/drivetime';
export const EMPLOYEES = '/employees';
export const TASKS = '/tasks';
export const DRIVERS = '/drivers';
export const WAREHOUSES = '/warehouses';
export const APPOINTMENTS = '/appointments';
export const CUSTOMERS = '/customers';
export const HEALTH = '/health';
export const SALES = '/sales';
export const DATA_STUDIO = '/dataStudio';
