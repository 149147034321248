import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const dashboards = `/${service}/dashboards`;

export function getDashboards(supervisorID) {
  const url = `${dashboards}?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}
