import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// Custom
import Dashboards from 'components/Dashboards';

// helpers
import { getJsonFromUrl, getUrlParams } from 'helpers';

// constants
import {
  DEFAULT_SUPERVISOR_ID,
  DEFAULT_WAREHOUSE_ID,
} from 'config';

// styles
import styles from './styles';

class ReportController extends Component {
  static propTypes = {
    // basics
    children: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    // reducers
    app: PropTypes.object,
    supervisors: PropTypes.array,
    warehouses: PropTypes.array,
    // status
    getDriveTimeStatus: PropTypes.func,
    getEmployeesStatus: PropTypes.func,
    getTasksStatus: PropTypes.func,
    getDriversStatus: PropTypes.func,
    getWarehousesStatus: PropTypes.func,
    getAppointmentStatus: PropTypes.func,
    getCustomerStatus: PropTypes.func,
    getHealthStatus: PropTypes.func,
    getDashboards: PropTypes.func,
    // action creators
    setCurrentSupervisor: PropTypes.func,
    setCurrentWarehouse: PropTypes.func,
  };

  state = {
    loading: true,
    dashboards: [],
  }

  componentWillReceiveProps(nextProps) {
    const nextUrlParams = getJsonFromUrl(nextProps.location);
    const urlParams = getJsonFromUrl(this.props.location);
    if ((this.props.supervisors.length !== nextProps.supervisors.length)
    || (nextUrlParams.supervisorID !== urlParams.supervisorID)
    || (this.props.warehouses.length !== nextProps.warehouses.length)
    || (nextUrlParams.warehouseID !== urlParams.warehouseID)) {
      this.setUrl(nextProps);
    }
  }

  getStatus() {
    const {
      app,
      getDriveTimeStatus,
      getEmployeesStatus,
      getTasksStatus,
      getDriversStatus,
      getWarehousesStatus,
      getAppointmentStatus,
      getCustomerStatus,
      getHealthStatus,
      getDashboards,
    } = this.props;
    const { currentWarehouse, currentSupervisor } = app;
    if (currentWarehouse && currentWarehouse.id) {
      Promise.all([
        getCustomerStatus(),
        getHealthStatus(),
        getDriveTimeStatus(currentWarehouse.id),
        getDriversStatus(currentWarehouse.id),
        getWarehousesStatus(currentWarehouse.id),
        getAppointmentStatus(currentWarehouse.id),
      ]);
    }
    if (currentSupervisor && currentSupervisor.id) {
      this.setState({ loading: true });
      Promise.all([
        getCustomerStatus(),
        getHealthStatus(),
        getEmployeesStatus(currentSupervisor.id),
        getTasksStatus(currentSupervisor.id),
        getDashboards(currentSupervisor.id),
      ]).then(r => this.setState({ dashboards: r[4].payload, loading: false }));
    }
  }


  async setUrl(nextProps) {
    const {
      app,
      supervisors,
      warehouses,
      location,
      history,
      setCurrentSupervisor,
      setCurrentWarehouse,
    } = nextProps;

    const { currentSupervisor, currentWarehouse } = app;
    const proms = [];
    if (supervisors && supervisors.length && warehouses && warehouses.length) {
      const urlParams = getJsonFromUrl(location);
      if (urlParams.supervisorID === undefined) { // nothing in the url
        if (currentSupervisor) { // app already has a supervisor set
          urlParams.supervisorID = currentSupervisor.id;
        } else { // no supervisor has been set. We set the default one
          urlParams.supervisorID = DEFAULT_SUPERVISOR_ID;
        }
      } else if ((currentSupervisor === undefined) || (Number(urlParams.supervisorID) !== currentSupervisor.id)) {
        const supervisor = supervisors.find(s => s.id === Number(urlParams.supervisorID));
        proms.push(setCurrentSupervisor(supervisor));
      }
      if (urlParams.warehouseID === undefined) {
        if (currentWarehouse) {
          urlParams.warehouseID = currentWarehouse.id;
        } else {
          urlParams.warehouseID = DEFAULT_WAREHOUSE_ID;
        }
      } else if ((currentWarehouse === undefined) || (Number(urlParams.warehouseID) !== currentWarehouse.id)) {
        const warehouse = warehouses.find(s => s.id === Number(urlParams.warehouseID));
        proms.push(setCurrentWarehouse(warehouse));
      }
      history.push({
        search: getUrlParams(urlParams),
      });
      if (proms.length) {
        const _currentSupervisor = this.props.app.currentSupervisor;
        const _currentWarehouse = this.props.app.currentWarehouse;
        if (_currentSupervisor && _currentWarehouse && (
          (_currentSupervisor.id !== Number(urlParams.supervisorID))
          || (_currentWarehouse.id !== Number(urlParams.warehouseID))
        )) {
          this.getStatus();
        }
        if ((_currentSupervisor === undefined) || (_currentWarehouse === undefined)) {
          await Promise.all(proms);
          this.getStatus();
        }
      }
    }
  }

  render() {
    const { children, getDashboards } = this.props;
    const { loading, dashboards } = this.state;

    return (
      <div>
        {
          children
        }
        <Dashboards
          getDashboards={getDashboards}
          dashboards={dashboards}
          loading={loading}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReportController);
