import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import WrapperHealthPage from '../component/WrapperHealthPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getHealthServices: (...args) => dispatch(Actions.getHealthServices(...args)),
});
class HealthPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getHealthServices: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentWarehouse) this.refresh([1, 2, 3, 4]);
  }

  initReport() {
    return (
      [
        {
          name: 'Services',
        },
      ]
    );
  }

  refresh(key) {
    const { getHealthServices } = this.props;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getHealthServices().then(r => this.resolve(0, r));
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperHealthPage
        history={history}
        location={location}
        reports={reports}
        key={app.currentWarehouse ? app.currentWarehouse.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HealthPage);
