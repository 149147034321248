import { networkAction } from 'helpers/network/networkAction';

import {
  GET_APPOINTMENT_STATUS,
  GET_APPOINTMENT_ISSUES_GRAPH,
  GET_APPOINTMENT_ISSUES,
} from 'constants/appointments';

import * as Api from 'api';

export const getAppointmentStatus = nodeID => async dispatch => networkAction(
  dispatch,
  GET_APPOINTMENT_STATUS,
  Api.getAppointmentStatus,
  [nodeID],
);

export const getAppointmentIssuesGraph = (nodeID, span) => async dispatch => networkAction(
  dispatch,
  GET_APPOINTMENT_ISSUES_GRAPH,
  Api.getWarehousesAppointmentIssuesGraph,
  [nodeID, span],
);

export const getAppointmentsIssues = (nodeID, span) => async dispatch => networkAction(
  dispatch,
  GET_APPOINTMENT_ISSUES,
  Api.getAppointmentsIssues,
  [nodeID, span],
);
