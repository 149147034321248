import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';
import TableReportValue from 'components/TableReportValue';

// styles
import styles from './styles';
import { Typography } from '@material-ui/core';

class Report extends Component {
  static propTypes = {
    classes: PropTypes.object,
    report: PropTypes.object,
  };

  minValue() {
    const { report } = this.props;
    const { values } = report;
    let min = values[0].value;
    
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const v = values[key];
        if (v.value < min) min = v.value;
      }
    }

    return min;
  }

  maxValue() {
    const { report } = this.props;
    const { values } = report;
    let max = values[0].value;
    
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const v = values[key];
        if (v.value > max) max = v.value;
      }
    }

    return max;
  }

  render() {
    const { report } = this.props;

    return (
      <Table
        data={report ? report.values : []}
        meta={[
          {
            path: 'name',
            title: 'Name',
            numeric: false,
          },
          {
            path: 'description',
            title: 'Description',
            numeric: false,
          },
          {
            path: 'value',
            title: 'Value',
            numeric: true,
            component: TableReportValue,
            inject: {
              prefix: report.prefix,
              suffix: report.suffix,
              thresholds: report.thresholds,
              trigger: report.trigger,
              min: this.minValue(),
              max: this.maxValue(),
            },
            width: 270,
          },
        ]}
        title={
          report
            ? (
              <div>
                <Typography variant="h6">
                  {report.title}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {report.description}
                </Typography>
              </div>
            ) : <div />
        }
        onRowSelect={async (w) => {
          console.log(w);
        }}
      />
    );
  }
}

export default withStyles(styles)(Report);
