import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import BrightnessHigh from '@material-ui/icons/BrightnessHigh';
import BrightnessLow from '@material-ui/icons/BrightnessLow';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Eye from '@material-ui/icons/RemoveRedEye';
import AppsIcon from '@material-ui/icons/Apps';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Home from '@material-ui/icons/Home';
import Person from '@material-ui/icons/Person';
import Location from '@material-ui/icons/LocationOn';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import { withCookies } from 'react-cookie';

// reactor
import Apps from 'components/Apps';
import App from 'components/App';

import {
  REACT_APP_FRONT_BASE,
  REACT_APP_LOGO,
} from 'config';

import {
  MY_ACCOUNT,
} from 'constants/routes';

// helpers
import { getJsonFromUrl, getUrlParams } from 'helpers';

// styles
import styles from './styles';

class Bar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    cookies: PropTypes.object,
    onMenuOpen: PropTypes.func,
    user: PropTypes.object,
    userApps: PropTypes.object,
    history: PropTypes.object,
    app: PropTypes.object,
    setDarkMode: PropTypes.func,
    stopImpersonate: PropTypes.func,
    signout: PropTypes.func,
    location: PropTypes.func,
    warehouses: PropTypes.array,
    supervisors: PropTypes.array,
    currentSupervisor: PropTypes.object,
  };

  state = {
    anchorEl: null,
    anchorEl3: null,
    appsOpen: false,
  };

  getSupervisorMenu() {
    const { supervisors, history, location, currentSupervisor } = this.props;
    const { anchorEl2 } = this.state;

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl2}
        open={Boolean(anchorEl2)}
        onClose={this.handleSupervisorClose}
      >
        {
          supervisors.map(s => (
            <MenuItem
              selected={currentSupervisor ? currentSupervisor.id === s.id : false}
              key={s.id}
              onClick={() => {
                const urlParams = getJsonFromUrl(location);
                urlParams.supervisorID = s.id;
                history.push({
                  search: getUrlParams(urlParams),
                });
                this.handleSupervisorClose();
              }
            }
            >
              {`${s.first_name} ${s.last_name}`}
            </MenuItem>
          ))
        }
      </Menu>
    );
  }

  getWarehouseMenu() {
    const { warehouses, history, location, currentWarehouse } = this.props;
    const { anchorEl3 } = this.state;
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl3}
        open={Boolean(anchorEl3)}
        onClose={this.handleWarehouseClose}
      >
        {
          warehouses.map(w => (
            <MenuItem
              selected={currentWarehouse ? currentWarehouse.id === w.id : false}
              key={w.id}
              onClick={() => {
                const urlParams = getJsonFromUrl(location);
                urlParams.warehouseID = w.id;
                history.push({
                  search: getUrlParams(urlParams),
                });
                this.handleWarehouseClose();
              }
            }
            >
              {w.name}
            </MenuItem>
          ))
        }
      </Menu>
    );
  }

  getMenu() {
    const {
      signout,
      history,
      app,
      setDarkMode,
      cookies,
      user,
      stopImpersonate,
      classes,
    } = this.props;

    const { anchorEl } = this.state;
    const accountOpen = Boolean(anchorEl);

    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableAutoFocusItem
        open={accountOpen}
        onClose={this.handleAppBarClose}
      >
        <Hidden smUp>
          {
            user && user.impersonate
              ? (
                <MenuItem
                  onClick={async () => {
                    await stopImpersonate();
                    window.location.reload();
                  }}
                  style={{
                    background: '#f44336',
                    color: 'white',
                  }}
                >
                  <ListItemIcon>
                    <Eye style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    classes={{
                      primary: classes.white,
                    }}
                    primary={`Stop Impersonating ${user.firstName} ${user.lastName}`} 
                  />
                </MenuItem>
              )
              : <div />
          }
        </Hidden>
        <Hidden smUp>
          <MenuItem
            onClick={() => this.setState({ appsOpen: true })}
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText inset primary="Apps" />
          </MenuItem>
        </Hidden>
        <MenuItem
          onClick={() => history.push(MY_ACCOUNT)}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText inset primary="My Account" />
        </MenuItem>
        <Hidden smUp>
          <MenuItem
            onClick={() => {
              setDarkMode(!app.darkMode);
              cookies.set(
                'darkMode',
                !app.darkMode,
                { path: '/' },
              );
            }}
          >
            <ListItemIcon>
              {
                app.darkMode
                  ? <BrightnessLow />
                  : <BrightnessHigh />
              }
            </ListItemIcon>
            <ListItemText inset primary={app.darkMode ? 'Light Mode' : 'Dark Mode'} />
          </MenuItem>
        </Hidden>
        <MenuItem
          onClick={async () => {
            await signout();
            window.location.replace(
              `${REACT_APP_FRONT_BASE}/signin`,
            );
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText inset primary="Sign out" />
        </MenuItem>
      </Menu>
    );
  }

  handleAppBarMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAppBarClose = () => {
    this.setState({ anchorEl: null });
  };


  handleSupervisorClick = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleSupervisorClose = () => {
    this.setState({ anchorEl2: null });
  };

  handleWarehouseClick = (event) => {
    this.setState({ anchorEl3: event.currentTarget });
  };

  handleWarehouseClose = () => {
    this.setState({ anchorEl3: null });
  };

  render() {
    const {
      classes,
      theme,
      onMenuOpen,
      user,
      userApps,
      history,
      app,
      setDarkMode,
      cookies,
      stopImpersonate,
      currentSupervisor,
      currentWarehouse,
    } = this.props;

    const { anchorEl, appsOpen } = this.state;
    const accountOpen = Boolean(anchorEl);

    return (
      <AppBar position="static" color="secondary">
        <Grid
          container
          justify="space-between"
          className={classes.appGrid}
          alignItems="center"
        >
          <Grid item>
            {/* Top Left Menu : Logo and Menu Icon */}
            <Grid container alignItems="center">
              <Grid item>
                <Hidden mdUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={onMenuOpen}
                  >
                    <MenuIcon fontSize="small" />
                  </IconButton>
                </Hidden>
              </Grid>
              <Hidden xsDown implementation="css">
                <Grid item>
                  <img
                    alt="logo"
                    src={REACT_APP_LOGO}
                    height="40"
                  />
                </Grid>
              </Hidden>
              <Grid item>
                <App
                  fullName
                  color={theme.palette.primary.color[500]}
                  name={'Reports'}
                  size={30}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* Desktop Top Right Menu */}
            <Hidden xsDown>
              <Grid container alignItems="center" spacing={8}>
                <Grid item>
                  <Chip
                    label={currentSupervisor ? `${currentSupervisor.first_name} ${currentSupervisor.last_name}` : '...'}
                    onClick={this.handleSupervisorClick.bind(this)}
                    avatar={(
                      <Avatar>
                        <Person />
                      </Avatar>
                    )}
                  />
                  {this.getSupervisorMenu()}
                </Grid>
                <Grid item>
                  <Chip
                    label={currentWarehouse ? currentWarehouse.name : '...'}
                    onClick={this.handleWarehouseClick.bind(this)}
                    avatar={(
                      <Avatar>
                        <Location />
                      </Avatar>
                    )}
                  />
                  {this.getWarehouseMenu()}
                </Grid>
                <Grid item>
                  <Apps
                    userApps={userApps}
                    history={history}
                    app={app}
                    baseHostname={REACT_APP_FRONT_BASE}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={app.darkMode ? 'Set Light Mode' : 'Set Dark Mode'} >
                    <IconButton
                      aria-label="DarkMode"
                      onClick={() => {
                        setDarkMode(!app.darkMode);
                        cookies.set(
                          'darkMode',
                          !app.darkMode,
                          { path: '/' },
                        );
                      }}
                    >
                      {
                        app.darkMode
                          ? <BrightnessLow />
                          : <BrightnessHigh style={{ color: 'white' }} />
                      }
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  {
                    user && user.impersonate
                      ? (
                        <Chip
                          label={`Impersonating ${user.firstName} ${user.lastName}`}
                          avatar={(
                            <Avatar
                              style={{
                                background: '#d32f2f',
                                color: 'white',
                              }}
                            >
                              <Eye />
                            </Avatar>
                          )}
                          style={{
                            background: '#f44336',
                            color: 'white',
                          }}
                          onDelete={async () => {
                            await stopImpersonate();
                            window.location.reload();
                          }}
                        />
                      )
                      : (
                        <Typography
                          variant="body2"
                          className={classes.AppBarGreetings}
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                      )
                  }
                </Grid>
                <Grid item>
                  <IconButton
                    aria-owns={accountOpen ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleAppBarMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  {this.getMenu()}
                </Grid>
              </Grid>
            </Hidden>
            {/* Mobile Top Right Menu */}
            <Hidden smUp>
              <Grid container>
                <Grid item>
                  <IconButton
                    onClick={this.handleSupervisorClick.bind(this)}
                    color="inherit"
                  >
                    <Person />
                  </IconButton>
                  {this.getSupervisorMenu()}
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={this.handleWarehouseClick.bind(this)}
                    color="inherit"
                  >
                    <Location />
                  </IconButton>
                  {this.getWarehouseMenu()}
                </Grid>
                <Grid item>
                  <IconButton
                    aria-owns={accountOpen ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleAppBarMenu}
                    color="inherit"
                    style={{
                      color: user && user.impersonate ? '#f44336' : undefined,
                    }}
                  >
                    <AccountCircle />
                  </IconButton>
                  {this.getMenu()}
                </Grid>
              </Grid>
              <Dialog
                fullScreen
                open={appsOpen}
                onClose={() => this.setState({ appsOpen: false })}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{"Select the app you want to navigate:"}</DialogTitle>
                <DialogContent>
                  <Grid container>
                    {
                      userApps.map(a => (
                        <Grid item xs={3}>
                          <App {...a} key={a.id} size={50} withName />
                        </Grid>
                      ))
                    }
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => window.location.replace(REACT_APP_FRONT_BASE)}
                    variant="outlined"
                  >
                    <Home /> Home
                  </Button>
                  <Button onClick={() => this.setState({ appsOpen: false })} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Hidden>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

export default withCookies(withStyles(styles, { withTheme: true })(Bar));
