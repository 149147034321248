export default theme => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'red',
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
});
