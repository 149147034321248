import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'v3/reports';
const sales = `/${service}/sales`;

export function getGoalRevenues(span) {
  const url = `${sales}/goalRevenues?span=${span}`;
  return {
    method: GET,
    url,
  };
}

export function getSalesStatus(supervisorID) {
  const url = `${sales}/status?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}


export function getGoalRevenueById(id) {
  const url = `${sales}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateGoalRevenue(id, body) {
  const url = `${sales}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGoalRevenue(id) {
  const url = `${sales}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGoalRevenue(body) {
  const url = `${sales}`;
  return {
    method: POST,
    url,
    body,
  };
}
