import {
  GET_WAREHOUSES,
} from 'constants/warehouses';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_WAREHOUSES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
