import { networkAction } from 'helpers/network/networkAction';

import {
  GET_GOAL_REVENUES,
  GET_GOAL_REVENUE_BY_ID,
  DELETE_GOAL_REVENUE,
  UPDATE_GOAL_REVENUE,
  CREATE_GOAL_REVENUE,
  GET_SALES_STATUS,
} from 'constants/goalRevenues';

import * as Api from 'api';

export const getGoalRevenues = span => async dispatch => networkAction(
  dispatch,
  GET_GOAL_REVENUES,
  Api.getGoalRevenues,
  [span],
);

export const getSalesStatus = () => async dispatch => networkAction(
  dispatch,
  GET_SALES_STATUS,
  Api.getSalesStatus,
  [],
);

export const getGoalRevenueById = id => async dispatch => networkAction(
  dispatch,
  GET_GOAL_REVENUE_BY_ID,
  Api.getGoalRevenueById,
  [id],
);

export const deleteGoalRevenue = id => async dispatch => networkAction(
  dispatch,
  DELETE_GOAL_REVENUE,
  Api.deleteGoalRevenue,
  [id],
);

export const createGoalRevenue = body => async dispatch => networkAction(
  dispatch,
  CREATE_GOAL_REVENUE,
  Api.createGoalRevenue,
  [body],
);

export const updateGoalRevenue = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_GOAL_REVENUE,
  Api.updateGoalRevenue,
  [id, body],
);
