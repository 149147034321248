import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperWorkspacePage from '../component/WrapperWorkspacePage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  workspaces: state.workspaces,
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  getWorkspaceById: (...args) => dispatch(Actions.getWorkspaceById(...args)),
  createWorkspace: (...args) => dispatch(Actions.createWorkspace(...args)),
  deleteWorkspace: (...args) => dispatch(Actions.deleteWorkspace(...args)),
  updateWorkspace: (...args) => dispatch(Actions.updateWorkspace(...args)),
  setWorkspaceToDefault: (...args) => dispatch(Actions.setWorkspaceToDefault(...args)),
  getWorkspaceUsers: (...args) => dispatch(Actions.getWorkspaceUsers(...args)),
  addWorkspaceUser: (...args) => dispatch(Actions.addWorkspaceUser(...args)),
  deleteWorkspaceUser: (...args) => dispatch(Actions.deleteWorkspaceUser(...args)),
  setCurrentWorkspace: (...args) => dispatch(Actions.setCurrentWorkspace(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
});
class WorkspacePage extends Component {
  static propTypes = {
    location: PropTypes.object,

    workspaces: PropTypes.array,
    getWorkspaces: PropTypes.func,
    getWorkspaceById: PropTypes.func,
    createWorkspace: PropTypes.func,
    deleteWorkspace: PropTypes.func,
    updateWorkspace: PropTypes.func,
    setCurrentWorkspace: PropTypes.func,
    setWorkspaceToDefault: PropTypes.func,

    getWorkspaceUsers: PropTypes.func,
    addWorkspaceUser: PropTypes.func,
    deleteWorkspaceUser: PropTypes.func,

    getUsers: PropTypes.func,
    users: PropTypes.array,

    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, workspaces } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: workspaces.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getUsers } = this.props;
    await Promise.all([
      getUsers(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      workspaces,
      getWorkspaceById,
      createWorkspace,
      deleteWorkspace,
      updateWorkspace,
      getWorkspaceUsers,
      addWorkspaceUser,
      deleteWorkspaceUser,
      setCurrentWorkspace,
      setWorkspaceToDefault,
      users,
      history,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperWorkspacePage
        refresh={this.refresh.bind(this)}
        users={users}
        loading={loading}
        workspaces={workspaces}
        getWorkspaceById={getWorkspaceById}
        createWorkspace={createWorkspace}
        deleteWorkspace={deleteWorkspace}
        updateWorkspace={updateWorkspace}
        getWorkspaceUsers={getWorkspaceUsers}
        addWorkspaceUser={addWorkspaceUser}
        deleteWorkspaceUser={deleteWorkspaceUser}
        setCurrentWorkspace={setCurrentWorkspace}
        setWorkspaceToDefault={setWorkspaceToDefault}
        urlParams={urlParams}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePage);
