export default theme => ({
  root: {
    width: '100%',
  },
  controls: {
    margin: theme.spacing.unit * 3,
  },
  exampleWrapper: {
    position: 'relative',
    height: 380,
  },
  radioGroup: {
    margin: `${theme.spacing.unit}px 0`,
  },
  speedDial: {
    position: 'absolute',
    '&$directionUp, &$directionLeft': {
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 3,
    },
    '&$directionDown, &$directionRight': {
      top: theme.spacing.unit * 2,
      left: theme.spacing.unit * 3,
    },
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  fab: {
    '&:hover': {
      background: '#616161',
    },
    background: '#424242',
  },
  button: {
    background: '#2196f3',
    '&:hover': {
      background: '#1976d2',
    },
  },
});
