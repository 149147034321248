import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';
import TableWorkspaceUsers from 'components/TableWorkspaceUsers';
import CurrentWorkspace from 'components/CurrentWorkspace';
import TableIsPublic from 'components/TableIsPublic';

// styles
import styles from './styles';

class WorkspacesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    workspaces: PropTypes.array,
    onSelect: PropTypes.func,
    onWorkspaceUsers: PropTypes.func,
    onSetCurrentWorkspace: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedWorkspace) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedWorkspace);
    }
  }

  onWorkspaceUsersClick(selectedWorkspace) {
    const { onWorkspaceUsers } = this.props;
    if (onWorkspaceUsers) {
      onWorkspaceUsers(selectedWorkspace);
    }
  }

  render() {
    const { workspaces, onSetCurrentWorkspace } = this.props;

    return (
      <Table
        data={workspaces}
        meta={[
          {
            path: 'name',
            title: 'Name',
            numeric: false,
          },
          {
            path: 'public',
            title: 'Public',
            numeric: false,
            width: 100,
            component: TableIsPublic,
          },
          {
            path: 'id',
            title: 'Current',
            numeric: false,
            component: CurrentWorkspace,
            inject: {
              onSetCurrentWorkspace,
            },
            width: 100,
          },
          {
            path: 'id',
            title: '',
            numeric: false,
            component: TableWorkspaceUsers,
            inject: {
              onClick: this.onWorkspaceUsersClick.bind(this),
              onEdit: this.onSelect.bind(this),
            },
            width: 100,
          },
        ]}
        title={'Workspaces'}
        onRowSelect={async (w) => {
          await onSetCurrentWorkspace(w);
          // history.push(DOCS);
        }}
      />
    );
  }
}

export default withStyles(styles)(WorkspacesList);
