import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';

// helper
import { getThresholdColor, getBackgroundGradient } from 'helpers';

// styles
import styles from './styles';

class TableReportValue extends Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    thresholds: PropTypes.array,
    trigger: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
  };

  state = {
    hover: false,
  }

  render() {
    const {
      datum,
      prefix,
      suffix,
      thresholds,
      trigger,
      classes,
      min,
      max,
    } = this.props;

    const { hover, show } = this.state;

    const background = getThresholdColor(
      thresholds,
      trigger,
      datum.value,
    );

    const backgroundGradient = getBackgroundGradient(
      thresholds,
      trigger,
      datum.value,
      min, max,
    );

    const label = `${prefix}${datum.value}${suffix}`;

    return (
      <div
        onMouseEnter={() => {
          this.setState({ hover: true });
          setTimeout(() => this.setState({ show: true }), 600);
        }}
        onMouseLeave={() => this.setState({ hover: false, show: false })}
        label={label}
        style={{
          textAlign: 'center',
          color: 'white',
          webkitTransition: 'width 0.6s, background 0.6s',
          background: hover
            ? backgroundGradient : background,
          width: hover ? 250 : label.length * 5 + 16,
          position: 'relative',
          height: 16,
        }}
        className={classes.chip}
      >
        <div>
          {
            hover
              ? (
                <div>
                  {
                    show
                      ? (
                        <Grow in>
                          <div
                            style={{
                              position: 'absolute',
                              left: (datum.value - min) * 250 / (max - min) - 10,
                              height: 20,
                              width: 20,
                              borderRadius: 20,
                              background: 'white',
                              marginTop: -2,
                              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                            }}
                          />
                        </Grow>
                      ): <div />
                  }
                  {
                    thresholds.map(t => (
                      <Tooltip title={`${prefix}${t}${suffix}`}>
                        <div
                          key={t}
                          style={{
                            position: 'absolute',
                            left: (t - min) * 250 / (max - min),
                          }}
                        >
                        I
                        </div>
                      </Tooltip>
                    ))
                  }
                </div>
              )
              : (
                <div style={{ position: 'absolute', width: 'calc(100% - 16px)' }}>
                  {label}
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TableReportValue);
