import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  DEFAULT,
  SIGNIN,
} from 'constants/routes';

import { menu } from 'config/menu';
import Signup from 'components/Signup';
import Upload from 'components/Upload';
import ForgotPassword from 'components/ForgotPassword';
import ResetPassword from 'components/ResetPassword';
import WebSocket from 'components/WebSocket';
import KeyboardEvents from 'components/KeyboardEvents';
import UrlActions from 'components/UrlActions';
import { withCookies } from 'react-cookie';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// components
import NotificationCenter from 'components/NotificationCenter';
import AppBar from 'components/AppBar';
import StyledBadge from 'components/StyledBadge';
import ReportController from 'components/ReportController';

// reactor
import AuthChecker from 'components/AuthChecker';

import {
  GOOGLE_ANALYTICS_KEY,
  REACT_APP_FRONT_BASE,
} from 'config';

import { getThresholdColor } from 'helpers';
import { redirectAccordingToRole } from 'helpers/redirect';

// styles
import styles from './styles';

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    allRoutes: PropTypes.array,
    history: PropTypes.object,
    theme: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.array,
    supervisors: PropTypes.array,
    warehouses: PropTypes.array,

    // Api
    getCurrentUser: PropTypes.func,
    getUserApps: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    stopImpersonate: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    getDriveTimeStatus: PropTypes.func,
    getAppointmentStatus: PropTypes.func,
    getWarehousesStatus: PropTypes.func,
    getDriversStatus: PropTypes.func,
    getEmployeesStatus: PropTypes.func,
    getTasksStatus: PropTypes.func,
    getCustomerStatus: PropTypes.func,
    getHealthStatus: PropTypes.func,
    getDashboards: PropTypes.func,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setCurrentSupervisor: PropTypes.func,
    setCurrentWarehouse: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    cookies: PropTypes.object,
    init: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      mobileOpen: false,
      anchorEl: null,
      miniActive: false,
      accountOpen: false,
      loading: true,
      alert: null,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  getMenu() {
    const {
      routes,
      history,
      user,
      location,
      classes,
      app,
    } = this.props;

    const { status } = app;

    const route = routes.find(r => r.path === location.pathname);

    const JSX = [];
    for (const k in menu) {
      if (menu.hasOwnProperty(k)) {
        const menuItem = menu[k];
        const Icon = menuItem.icon;

        const currentRoute = routes.find(e => e.path === menuItem.path);

        if (currentRoute && currentRoute.onEnter && currentRoute.onEnter()) {
          let selected = false;
          if (menuItem.path && menuItem.path === route.path) {
            selected = true;
          }

          JSX.push(
            <ListItem
              selected={selected && menuItem.nested === undefined}
              key={menuItem.path}
              button
              divider={menuItem.divider || Number(k) === menu.length - 1}
              onClick={() => {
                if (menuItem.path) {
                  history.push(menuItem.path);
                  if (menuItem.nested) {
                    if (this.state[menuItem.label] === undefined) {
                      this.setState({ [menuItem.label]: true });
                    } else {
                      this.setState(prevState => ({
                        [menuItem.label]: !prevState[menuItem.label],
                      }));
                    }
                  }
                }
              }}
            >
              <ListItemIcon>
                <StyledBadge
                  backgroundColor={status[menuItem.path] ? getThresholdColor(
                    status[menuItem.path].thresholds,
                    status[menuItem.path].trigger,
                    status[menuItem.path].glanceValue,
                  ) : undefined}
                >
                  <Icon
                    className={
                      selected && menuItem.nested === undefined
                        ? classes.selected
                        : undefined
                    }
                  />
                </StyledBadge>
              </ListItemIcon>
              <ListItemText
                inset
                classes={{
                  primary: selected && menuItem.nested === undefined ? classes.selected : undefined,
                }}
                primary={menuItem.label}
              />
              {
                menuItem.nested
                && (
                  <div>
                    {
                      this.state[menuItem.label]
                        ? <ExpandLess />
                        : <ExpandMore />
                    }
                  </div>
                )
              }
            </ListItem>,
          );

          if (menuItem.nested) {
            const nestedMenu = [];
            for (const m in menuItem.nested) {
              if (menuItem.nested.hasOwnProperty(m)) {
                const n = menuItem.nested[m];
                const NestedIcon = n.icon;
                if (n.path && n.path === route.path) {
                  selected = true;
                } else {
                  selected = false;
                }

                nestedMenu.push(
                  <ListItem
                    selected={selected}
                    key={`nested_${n.path}`}
                    button
                    className={classes.nested}
                    divider={n.divider || Number(k) === menu.length - 1}
                    onClick={() => {
                      if (n.path) {
                        history.push(n.path);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <NestedIcon
                        className={selected ? classes.selected : classes.nestedWhite}
                      />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      classes={{
                        primary: selected ? classes.selected : classes.nestedWhite,
                      }}
                      primary={n.label}
                    />
                  </ListItem>,
                );
              }
            }

            JSX.push(
              <Collapse
                key={`collapse_${menuItem.path}`}
                in={this.state[menuItem.label] !== undefined
                  ? this.state[menuItem.label]
                  : menuItem.nested.find(n => n.path === route.path) !== undefined
                }
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding className={classes.background}>
                  {
                    nestedMenu
                  }
                </List>
              </Collapse>,
            );
          }
        }
      }
    }

    return (
      <div>
        <div className={classes.toolbar} />
        <List
          className={classes.list}
          component="nav"
        >
          {JSX}
          {
            user.services === undefined
            && (
              <ListItem
                key={'signin'}
                button
                divider
                onClick={() => {
                  history.push(`${REACT_APP_FRONT_BASE}/signin`);
                }}
              >
                <ListItemIcon>
                  <Fingerprint />
                </ListItemIcon>
                <ListItemText inset primary={'Signin'} />
              </ListItem>
            )
          }
        </List>
      </div>
    );
  }

  handleDrawerToggle = () => {
    this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div>
        <Switch>
          {
            routes.map(route => (
              route.path === DEFAULT
                ? (
                  <Route
                    key={route.path}
                    component={route.component}
                  />
                )
                : (
                  <Route
                    key={route.path}
                    path={route.path}
                    component={route.component}
                  />
                )
            ))
          }
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      signout,
      storeResetPasswordToken,
      app,
      resetPassword,
      user,
      theme,
      setDarkMode,
      userApps,
      allRoutes,
      init,
      stopImpersonate,
      supervisors,
      warehouses,
      getDriveTimeStatus,
      getEmployeesStatus,
      getWarehousesStatus,
      getCustomerStatus,
      getHealthStatus,
      getDriversStatus,
      getAppointmentStatus,
      getTasksStatus,
      getDashboards,
      setCurrentSupervisor,
      setCurrentWarehouse,
    } = this.props;

    const {
      loading,
      alert,
    } = this.state;

    const { currentSupervisor, currentWarehouse } = app;

    const route = routes.find(r => r.path === location.pathname);

    return (
      <div>
        <AuthChecker
          routes={allRoutes}
          history={history}
          location={location}
          user={user}
          getCurrentUser={getCurrentUser}
          onReady={() => { this.setState({ loading: false }); }}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          init={init}
        >
          <NotificationCenter
            errors={errors}
          >
            <WebSocket user={user}>
              <KeyboardEvents>
                <UrlActions
                  location={location}
                  history={history}
                  validateEmail={validateEmail}
                  storeResetPasswordToken={storeResetPasswordToken}
                >
                  <Signup
                    history={history}
                    signup={signup}
                    validateEmail={validateEmail}
                  >
                    <ForgotPassword
                      history={history}
                      requestResetPassword={requestResetPassword}
                    >
                      <ResetPassword
                        token={app.resetPasswordToken}
                        resetPassword={resetPassword}
                        storeResetPasswordToken={storeResetPasswordToken}
                      >
                        <ReportController
                          location={location}
                          history={history}
                          app={app}
                          supervisors={supervisors}
                          warehouses={warehouses}
                          getDriveTimeStatus={getDriveTimeStatus}
                          getWarehousesStatus={getWarehousesStatus}
                          getEmployeesStatus={getEmployeesStatus}
                          getAppointmentStatus={getAppointmentStatus}
                          getCustomerStatus={getCustomerStatus}
                          getHealthStatus={getHealthStatus}
                          getTasksStatus={getTasksStatus}
                          getDriversStatus={getDriversStatus}
                          setCurrentSupervisor={setCurrentSupervisor}
                          setCurrentWarehouse={setCurrentWarehouse}
                          getDashboards={getDashboards}
                        >
                          <Upload>
                            {
                              loading
                                ? (
                                  <Grid
                                    container
                                    className={classes.root}
                                    alignContent="center"
                                    alignItems="center"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    >
                                      <CircularProgress style={{ color: '#ffffff' }} />
                                    </Grid>
                                  </Grid>
                                )
                                : (
                                  <div className={classes.root}>
                                    {
                                      route && route.withSidebar
                                      && (
                                        <div>
                                          <Hidden mdUp>
                                            <Drawer
                                              variant="temporary"
                                              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                              open={this.state.mobileOpen}
                                              onClose={this.handleDrawerToggle}
                                              classes={{
                                                paper: classes.drawerPaper,
                                              }}
                                              ModalProps={{
                                                keepMounted: true,
                                              }}
                                            >
                                              <div className={classes.drawerContainer}>
                                                {this.getMenu()}
                                              </div>
                                            </Drawer>
                                          </Hidden>
                                          <Hidden smDown implementation="css">
                                            <Drawer
                                              onMouseEnter={() => {
                                                this.setState({ miniActive: true });
                                              }}

                                              onMouseLeave={() => {
                                                this.setState({ miniActive: false });
                                              }}

                                              variant="permanent"
                                              open={!this.state.miniActive}
                                              classes={{
                                                paper: classNames(
                                                  classes.drawerPaper,
                                                  !this.state.miniActive && classes.drawerPaperClose,
                                                ),
                                              }}
                                            >
                                              <div className={classes.drawerContainer}>
                                                {this.getMenu()}
                                              </div>
                                            </Drawer>
                                          </Hidden>
                                        </div>
                                      )
                                    }
                                    <main className={classes.content}>
                                      {
                                        route && route.withAppBar
                                        && (
                                          <AppBar
                                            onMenuOpen={this.handleDrawerToggle.bind(this)}
                                            user={user}
                                            userApps={userApps}
                                            history={history}
                                            app={app}
                                            setDarkMode={setDarkMode}
                                            stopImpersonate={stopImpersonate}
                                            signout={signout}
                                            location={location}
                                            warehouses={warehouses}
                                            supervisors={supervisors}
                                            currentSupervisor={currentSupervisor}
                                            currentWarehouse={currentWarehouse}
                                          />
                                        )
                                      }
                                      {this.injectRoutes()}
                                      {
                                        alert
                                      }
                                    </main>
                                  </div>
                                )
                            }
                          </Upload>
                        </ReportController>
                      </ResetPassword>
                    </ForgotPassword>
                  </Signup>
                </UrlActions>
              </KeyboardEvents>
            </WebSocket>
          </NotificationCenter>
        </AuthChecker>
      </div>
    );
  }
}

export default withTheme()(withCookies(withRouter(connect()(withStyles(styles, { withTheme: true })(WrapperRootPage)))));
