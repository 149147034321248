import * as React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core';

const Content = props => {
    const styles = theme => ({
        content:{
            "background-color" : props.backgroundColor
        }
    });
    const div = props => (
        <Badge variant="dot" classes={{ dot: props.classes.content }}>
            {props.children}
        </Badge>
    );
    const Styled = withStyles(styles)(div);
    return (
        <Styled>{props.children}</Styled>
    );
}

export default Content;