import { networkAction } from 'helpers/network/networkAction';

import {
  GET_DASHBOARDS,
} from 'constants/dashboards';

import * as Api from 'api';

export const getDashboards = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_DASHBOARDS,
  Api.getDashboards,
  [supervisorID],
);
