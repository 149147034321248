import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

class GraphReport extends Component {
  static propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
  };

  render() {
    const { config } = this.props;

    return (
      <div>
        {
          config && config.series
            ? (
              <Chart
                options={config.options}
                series={config.series}
                type={config.type}
                key={config.key}
              />
            )
            : <div />
        }
      </div>
    );
  }
}

export default withStyles(styles)(GraphReport);
