import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';

// custom
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableRemove from 'components/TableRemove';
import AutoComplete from 'components/AutoComplete';

// styles
import styles from './styles';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogWorkspaceUsers extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    close: PropTypes.func,
    users: PropTypes.array,
    workspace: PropTypes.object,
    getWorkspaceUsers: PropTypes.func,
    addWorkspaceUser: PropTypes.func,
    deleteWorkspaceUser: PropTypes.func,
  };

  state = {
    loading: true,
    addUserOpen: false,
  };

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;

    if (nextProps.open && !open) {
      const { workspace } = nextProps;
      if (workspace && workspace.id) {
        this.refresh(workspace);
      }
    }
  }

  async onRemove(user) {
    const { workspace, deleteWorkspaceUser } = this.props;
    await deleteWorkspaceUser(workspace.id, user.uuid);
    this.refresh(workspace);
  }

  async addUserToWorkspace(user) {
    const { workspace, addWorkspaceUser } = this.props;
    this.closeAddUser();
    await addWorkspaceUser(workspace.id, user.uuid);
    this.refresh(workspace);
  }

  async refresh(workspace) {
    const { getWorkspaceUsers } = this.props;
    this.setState({ loading: true });

    const resp = await getWorkspaceUsers(workspace.id);
    if (resp.success) {
      const workspaceUsers = resp.payload;
      this.setState({ workspaceUsers, loading: false });
    }
  }

  closeAddUser() {
    this.setState({ addUserOpen: false });
  }

  openAddUser() {
    this.setState({ addUserOpen: true });
  }

  render() {
    const {
      classes,
      open,
      close,
      workspace,
      users,
    } = this.props;

    const {
      loading,
      workspaceUsers,
      addUserOpen,
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={close}
        TransitionComponent={Transition}
        classes={{
          paper: classes.background,
        }}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={close}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {`${workspace ? workspace.name : ''} workspace`}
            </Typography>
            <Button color="inherit" onClick={close}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          {
            loading
              ? (
                <Loading
                  loading={loading}
                  message={'Fetching rules'}
                />
              )
              : (
                <Table
                  data={workspaceUsers}
                  meta={[
                    {
                      path: 'firstName',
                      title: 'FirstName',
                      numeric: false,
                    },
                    {
                      path: 'lastName',
                      title: 'LastName',
                      numeric: false,
                    },
                    {
                      path: 'email',
                      title: 'Email',
                      numeric: false,
                    },
                    {
                      path: 'id',
                      title: 'Remove',
                      numeric: false,
                      component: TableRemove,
                      inject: {
                        onRemove: this.onRemove.bind(this)
                      },
                      width: 100,
                    },
                  ]}
                  title={'Workspaces'}
                  onRowSelect={() => console.log('onRowSelect')}
                />
              )
          }
        </div>
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={this.openAddUser.bind(this)}
        >
          <AddIcon />
        </Button>
        <Dialog
          open={addUserOpen}
          onClose={this.closeAddUser.bind(this)}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Add user to workspace {workspace && workspace.name}
          </DialogTitle>
          <div
            style={{
              padding: 20,
            }}
          >
            <AutoComplete
              placeHolder={'Search for a User'}
              onSelect={i => this.addUserToWorkspace(i.value)}
              suggestions={
                users && users.map(user => ({
                  value: user,
                  label: `${user.firstName} ${user.lastName} (${user.uuid})`,
                }))
              }
            />
          </div>
        </Dialog>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogWorkspaceUsers);
