import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// component
import WrapperSigninPage from '../component/WrapperSigninPage';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = dispatch => ({
  signin: (...args) => dispatch(Actions.signin(...args)),
  setCurrentWorkspace: (...args) => dispatch(Actions.setCurrentWorkspace(...args)),
});
class SigninPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getWorkspaces: PropTypes.func,
    setCurrentWorkspace: PropTypes.func,

    // api
    signin: PropTypes.func,

    // reducer
    session: PropTypes.object,
    app: PropTypes.object,
  };

  render() {
    const {
      history,
      location,
      signin,
      session,
      app,
      getWorkspaces,
      setCurrentWorkspace,
    } = this.props;
    return (
      <WrapperSigninPage
        history={history}
        location={location}
        signin={signin}
        session={session}
        setCurrentWorkspace={setCurrentWorkspace}
        getWorkspaces={getWorkspaces}
        app={app}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
