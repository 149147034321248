import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const tasks = `/${service}/tasks`;

export function getTasksStatus(supervisorID) {
  const url = `${tasks}/status?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getTimeOffRequests(supervisorID) {
  const url = `${tasks}/timeOffRequests?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getDriverSafetyBonus(supervisorID) {
  const url = `${tasks}/driverSafetyBonus?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getSupervisorBonus(supervisorID) {
  const url = `${tasks}/supervisorBonus?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getTimeSheets(supervisorID) {
  const url = `${tasks}/timeSheets?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getEmployeeReviews(supervisorID) {
  const url = `${tasks}/employeeReviews?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getDriverAccessorials(supervisorID) {
  const url = `${tasks}/driverAccessorials?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getEmployeeAccessorials(supervisorID) {
  const url = `${tasks}/employeeAccessorials?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getDriverOfTheMonth(supervisorID) {
  const url = `${tasks}/driverOfTheMonth?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}
