import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// styles
import styles from './styles';

class TableIsPublic extends Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <Typography>
        {datum.public ? 'Public' : 'Private'}
      </Typography>
    );
  }
}

export default withStyles(styles)(TableIsPublic);
