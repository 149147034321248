import { networkAction } from 'helpers/network/networkAction';

import {
  GET_CUSTOMER_STATUS,
  GET_CUSTOMER_RATINGS,
} from 'constants/customers';

import * as Api from 'api';

export const getCustomerStatus = () => async dispatch => networkAction(
  dispatch,
  GET_CUSTOMER_STATUS,
  Api.getCustomerStatus,
  [],
);

export const getCustomersRatings = () => async dispatch => networkAction(
  dispatch,
  GET_CUSTOMER_RATINGS,
  Api.getCustomersRatings,
  [],
);
