import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import Report from 'components/Report';
import GraphReport from 'components/GraphReport';

import WrapperWarehousePage from '../component/WrapperWarehousesPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getWarehouseCargoConsolidation: (...args) => dispatch(Actions.getWarehouseCargoConsolidation(...args)),
  getWarehouseCargoConsolidationGraph: (...args) => dispatch(Actions.getWarehouseCargoConsolidationGraph(...args)),
});
class WarehousePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getWarehouseCargoConsolidation: PropTypes.func,
    getWarehouseCargoConsolidationGraph: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentWarehouse) this.refresh([1, 2]);
  }

  initReport() {
    return (
      [
        {
          name: 'Cargo Cons',
          component: Report,
        },
        {
          name: 'Graph',
          component: GraphReport,
        },
      ]
    );
  }

  componentWillReceiveProps(nextProps) {
    const nextWarehouse = nextProps.app.currentWarehouse;
    const currentWarehouse = this.props.app.currentWarehouse;
    if ((currentWarehouse === undefined) && (nextWarehouse !== undefined)) {
      setTimeout(() => this.refresh([1, 2]), 500);
    } else if (currentWarehouse && nextWarehouse && (currentWarehouse.id !== nextWarehouse.id)) {
      setTimeout(() => this.refresh([1, 2]), 500);
    }
  }

  refresh(key) {
    const { getWarehouseCargoConsolidation, getWarehouseCargoConsolidationGraph, app } = this.props;
    const { currentWarehouse } = app;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getWarehouseCargoConsolidation(currentWarehouse.id).then(r => this.resolve(0, r));
    }
    if (key.find(k => k === 2)) {
      getWarehouseCargoConsolidationGraph().then((r) => {
        this.resolve(1, r);
        this.getWarehouseCargoConsolidationGraphConfig(1, r);
      });
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  getWarehouseCargoConsolidationGraphConfig(index, r) {
    const { reports } = this.state;
    if (r.success) {
      const report = r.payload;
      report.values.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      });

      const dataPerWeek = {};
      const locations = {};
      const colors = [];
      for (const k in report.values) {
        if (report.values.hasOwnProperty(k)) {
          const value = report.values[k];
          const key = value.description.split('for ')[1];
          colors.push('#777777');
          if (dataPerWeek[key]) {
            dataPerWeek[key][value.name] = value.value;
            locations[value.name] = 1;
          } else {
            dataPerWeek[key] = {
            };
            dataPerWeek[key][value.name] = value.value;
            locations[value.name] = 1;
          }
        }
      }

      // const options = {
      //   chart: {
      //     id: 'WarehouseCargoConsolidationGraph',
      //   },
      //   xaxis: {
      //     categories: Object.keys(dataPerWeek),
      //   },
      //   colors: ['#f44336', '#3f51b5', '#2196f3', '#009688', '#9c27b0', '#4caf50', '#ff9800'],
      //   stroke: {
      //     curve: 'smooth',
      //   },
      // };
      const options = {
        chart: {
          id: 'WarehouseCargoConsolidationGraph',
        },
        colors: ['#f44336', '#3f51b5', '#2196f3', '#009688', '#9c27b0', '#4caf50', '#ff9800'],
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          categories: Object.keys(dataPerWeek),
          labels: {
            style: {
              colors,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              color: '#777777',
            },
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        legend: {
          labels: {
            colors: '#777777',
            useSeriesColors: false,
          },
        },
      };
      const series = [];

      for (const key in locations) {
        if (locations.hasOwnProperty(key)) {
          const serie = {
            name: key,
            data: [],
          };
          for (const k in dataPerWeek) {
            if (dataPerWeek.hasOwnProperty(k)) {
              const dpw = dataPerWeek[k];
              serie.data.push(dpw[key]);
            }
          }
          series.push(serie);
        }
      }

      reports[index].config = { options, series };
      this.setState({ reports });
    }
  }


  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperWarehousePage
        history={history}
        location={location}
        reports={reports}
        key={app.currentWarehouse ? app.currentWarehouse.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WarehousePage);
