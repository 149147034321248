import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import People from '@material-ui/icons/PeopleTwoTone';
import Edit from '@material-ui/icons/EditTwoTone';
import Grid from '@material-ui/core/Grid';

// styles
import styles from './styles';

class TableRolesRules extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
  };

  render() {
    const { datum, onClick, onEdit } = this.props;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item>
          <Tooltip title={'Manage Users'}>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(datum);
              }}
            >
              <People />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={'Edit'}>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEdit(datum);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TableRolesRules);
