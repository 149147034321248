import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import WrapperDriverPage from '../component/WrapperDriversPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getDriversMpg: (...args) => dispatch(Actions.getDriversMpg(...args)),
  getDriversLate: (...args) => dispatch(Actions.getDriversLate(...args)),
});
class DriverPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getDriversMpg: PropTypes.func,
    getDriversLate: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentWarehouse) this.refresh([1, 2, 3, 4, 5, 6]);
  }

  initReport() {
    return (
      [
        {
          name: 'MPG Day',
        },
        {
          name: 'MPG Week',
        },
        {
          name: 'MPG Month',
        },
        {
          name: 'MPG Year',
        },
        {
          name: 'Late Day',
        },
        {
          name: 'Late Month',
        },
      ]
    );
  }

  componentWillReceiveProps(nextProps) {
    const nextWarehouse = nextProps.app.currentWarehouse;
    const currentWarehouse = this.props.app.currentWarehouse;
    if ((currentWarehouse === undefined) && (nextWarehouse !== undefined)) {
      setTimeout(() => this.refresh([1, 2, 3, 4, 5, 6]), 500);
    } else if (currentWarehouse && nextWarehouse && (currentWarehouse.id !== nextWarehouse.id)) {
      setTimeout(() => this.refresh([1, 2, 3, 4, 5, 6]), 500);
    }
  }

  refresh(key) {
    const { getDriversMpg, getDriversLate, app } = this.props;
    const { currentWarehouse } = app;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getDriversMpg(currentWarehouse.id, 'day').then(r => this.resolve(0, r));
    }
    if (key.find(k => k === 2)) {
      getDriversMpg(currentWarehouse.id, 'week').then(r => this.resolve(1, r));
    }
    if (key.find(k => k === 3)) {
      getDriversMpg(currentWarehouse.id, 'month').then(r => this.resolve(2, r));
    }
    if (key.find(k => k === 4)) {
      getDriversMpg(currentWarehouse.id, 'year').then(r => this.resolve(3, r));
    }
    if (key.find(k => k === 4)) {
      getDriversLate(currentWarehouse.id, 'day').then(r => this.resolve(4, r));
    }
    if (key.find(k => k === 4)) {
      getDriversLate(currentWarehouse.id, 'month').then(r => this.resolve(5, r));
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperDriverPage
        history={history}
        location={location}
        reports={reports}
        key={app.currentWarehouse ? app.currentWarehouse.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DriverPage);
