import { SALES } from 'constants/routes';
import { checkAccess } from 'helpers/auth';
import SalesPage from './container/SalesPage';

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: SALES,
    withSidebar: true,
    withAppBar: true,
    component: SalesPage,
  };
}
