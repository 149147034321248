import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

// styles
import styles from './styles';

class CurrentWorkspace extends Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
    history: PropTypes.object,
    onSetCurrentWorkspace: PropTypes.func,
  };

  handleClick(e) {
    const { datum, history } = this.props;
    e.preventDefault();
    e.stopPropagation();
    history.push(`/doc?p=${datum.id}`);
  }

  render() {
    const { classes, datum } = this.props;

    if (datum.default) {
      return (
        <Chip
          label="Current Workspace"
          className={classes.chip}
          color="primary"
        />
      );
    }

    return <div />;

    // return (
    //   <Button
    //     size="small"
    //     variant="outlined"
    //     color="primary"
    //     className={classes.button}
    //     onClick={(e) => {
    //       e.preventDefault();
    //       e.stopPropagation();
    //       onSetCurrentWorkspace(datum);
    //     }}
    //   >
    //     Set As Current
    //   </Button>
    // );
  }
}

export default withStyles(styles)(CurrentWorkspace);
