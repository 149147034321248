import goalRevenues from './goalRevenues';
import supervisors from './supervisors';
import warehouses from './warehouses';
import workspaces from './workspaces';
import rules from './rules';
import roles from './roles';
import groups from './groups';
import users from './users';
import errors from './errors';
import app from './app';
import user from './user';
import userApps from './userApps';
import serviceNames from './serviceNames';
import { combineReducers } from 'redux'; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  app,
  users,
  groups,
  roles,
  rules,
  userApps,
  serviceNames,
  workspaces,
  supervisors,
  warehouses,
  goalRevenues,
});
