import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import Report from 'components/Report';
import GraphReport from 'components/GraphReport';

import WrapperAppointmentPage from '../component/WrapperAppointmentsPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getAppointmentsIssues: (...args) => dispatch(Actions.getAppointmentsIssues(...args)),
  getAppointmentIssuesGraph: (...args) => dispatch(Actions.getAppointmentIssuesGraph(...args)),
});
class AppointmentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getAppointmentsIssues: PropTypes.func,
    getAppointmentIssuesGraph: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentWarehouse) this.refresh([1, 2]);
  }

  initReport() {
    return (
      [
        {
          name: 'Appt Issues Month',
          component: Report,
        },
        {
          name: 'Graph',
          component: GraphReport,
        },
      ]
    );
  }

  componentWillReceiveProps(nextProps) {
    const nextWarehouse = nextProps.app.currentWarehouse;
    const currentWarehouse = this.props.app.currentWarehouse;
    if ((currentWarehouse === undefined) && (nextWarehouse !== undefined)) {
      setTimeout(() => this.refresh([1, 2, 3, 4]), 500);
    } else if (currentWarehouse && nextWarehouse && (currentWarehouse.id !== nextWarehouse.id)) {
      setTimeout(() => this.refresh([1, 2, 3, 4]), 500);
    }
  }

  refresh(key) {
    const { getAppointmentsIssues, getAppointmentIssuesGraph, app } = this.props;
    const { currentWarehouse } = app;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getAppointmentsIssues(currentWarehouse.id, 'month').then(r => this.resolve(0, r));
    }
    if (key.find(k => k === 2)) {
      getAppointmentIssuesGraph(currentWarehouse.id, 'quarter').then((r) => {
        this.resolve(1, r);
        this.getAppointmentIssuesGraphConfig(1, r);
      });
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  getAppointmentIssuesGraphConfig(index, r) {
    const { reports } = this.state;
    if (r.success) {
      const report = r.payload;
      const dataPerName = {};
      const types = {};
      const colors = [];
      for (const k in report.values) {
        if (report.values.hasOwnProperty(k)) {
          const value = report.values[k];
          const name = value.name;
          types[name] = 1;
          colors.push('#777777');
          if (dataPerName[name]) {
            dataPerName[name].push([new Date(value.date).getTime(), value.value]);
          } else {
            dataPerName[name] = [];
            dataPerName[name].push([new Date(value.date).getTime(), value.value]);
          }
        }
      }
      const options = {
        chart: {
          id: 'CustomerSatisfaction',
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          labels: {
            style: {
              colors,
            },
          },
        },
        yaxis: {
          max: 100,
          min: 98,
          labels: {
            style: {
              color: '#777777',
            },
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        legend: {
          labels: {
            colors: '#777777',
            useSeriesColors: false,
          },
        },
        colors: ['#f44336', '#3f51b5', '#2196f3', '#009688', '#9c27b0', '#4caf50', '#ff9800'],
      };
      // const options = {
      //   chart: {
      //     id: 'WarehouseCargoConsolidationGraph',
      //   },
      //   xaxis: {
      //     categories: Object.keys(dates),
      //   },
      //   yaxis: {
      //     max: 100,
      //     min: 99,
      //   },
      //   colors: ['#f44336', '#3f51b5', '#2196f3', '#009688', '#9c27b0', '#4caf50', '#ff9800'],
      //   stroke: {
      //     curve: 'smooth',
      //   },
      // };
      const series = [];

      for (const key in dataPerName) {
        if (dataPerName.hasOwnProperty(key)) {
          const dpn = dataPerName[key];
          const serie = {
            name: key,
            data: dpn,
          };
          series.push(serie);
        }
      }

      reports[index].config = { options, series };
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperAppointmentPage
        history={history}
        location={location}
        reports={reports}
        key={app.currentWarehouse ? app.currentWarehouse.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPage);
