import { networkAction } from 'helpers/network/networkAction';

import {
  GET_DRIVERS_STATUS,
  GET_DRIVERS_MPG,
  GET_DRIVERS_LATE,
} from 'constants/drivers';

import * as Api from 'api';

export const getDriversStatus = nodeID => async dispatch => networkAction(
  dispatch,
  GET_DRIVERS_STATUS,
  Api.getDriversStatus,
  [nodeID],
);

export const getDriversMpg = (nodeID, span) => async dispatch => networkAction(
  dispatch,
  GET_DRIVERS_MPG,
  Api.getDriversMpg,
  [nodeID, span],
);

export const getDriversLate = (nodeID, span) => async dispatch => networkAction(
  dispatch,
  GET_DRIVERS_LATE,
  Api.getDriversLate,
  [nodeID, span],
);
