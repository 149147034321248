import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import GraphReport from 'components/GraphReport';

import WrapperCustomerPage from '../component/WrapperCustomersPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getCustomersRatings: (...args) => dispatch(Actions.getCustomersRatings(...args)),
});
class CustomerPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getCustomersRatings: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      reports: this.initReport(),
    };
    this.refresh([1]);
  }

  initReport() {
    return (
      [
        {
          name: 'Satisfaction',
          component: GraphReport,
        },
      ]
    );
  }

  refresh(key) {
    const { getCustomersRatings } = this.props;
    this.setState({ reports: this.initReport() });
    if (key.find(k => k === 1)) {
      getCustomersRatings().then((r) => {
        this.resolve(0, r);
        this.getRatingGraphConfig(0, r);
      });
    }
  }

  getRatingGraphConfig(index, r) {
    const { reports } = this.state;
    if (r.success) {
      const data = [];
      const colors = [];
      const report = r.payload;
      for (const key in report.values) {
        if (report.values.hasOwnProperty(key)) {
          const element = report.values[key];
          data.push([new Date(element.date).getTime(), element.value]);
          colors.push('#777777');
        }
      }

      const options = {
        chart: {
          id: 'CustomerSatisfaction',
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          labels: {
            style: {
              colors,
            },
          },
        },
        yaxis: {
          max: 5,
          min: 0,
          labels: {
            style: {
              color: '#777777',
            },
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0,
            opacityFrom: 0.9,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
        stroke: {
          curve: 'smooth',
        },
      };
      const series = [{
        name: 'Customer Satisfaction',
        data,
      }];

      reports[index].config = { options, series, type: 'area' };
      this.setState({ reports });
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperCustomerPage
        history={history}
        location={location}
        reports={reports}
        key={app.currentWarehouse ? app.currentWarehouse.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
