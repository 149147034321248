import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// material-ui
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import Zoom from '@material-ui/core/Zoom';
import Email from '@material-ui/icons/Email';
import LockOutline from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

// creativeTim components
import HeaderLinks from 'creativeTim/Header/HeaderLinks';
import Header from 'creativeTim/Header/Header';
import Button from 'creativeTim/CustomButtons/Button';
import Card from 'creativeTim/Card/Card';
import CardBody from 'creativeTim/Card/CardBody';
import CardHeader from 'creativeTim/Card/CardHeader';
import CardFooter from 'creativeTim/Card/CardFooter';
import CustomInput from 'creativeTim/CustomInput/CustomInput';

// custom
import { Copyright } from 'components';

// helpers
import { redirectAccordingToRole } from 'helpers/redirect';
import { isEmail } from 'helpers/form/typeCheckers';

// reactor
import App from 'components/App';

// config
import {
  SIGN_UP_ENABLED,
  RESET_PASSWORD_ENABLED,
  REACT_APP_LOGO,
} from 'config';

// styles
import styles from './styles';

class WrapperSigninPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    history: PropTypes.object,
    setCurrentWorkspace: PropTypes.func,
    getWorkspaces: PropTypes.func,
    app: PropTypes.object,

    // Api
    signin: PropTypes.func,
  };

  static contextTypes = {
    openSignup: PropTypes.func,
    openForgotPassword: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      email: '',
      emailBlurred: false,
      password: '',
      passwordBlurred: false,
    };
  }

  async signin() {
    const { signin, history } = this.props;
    const { email, password } = this.state;

    const { localStorage } = window;
    const { redirectUrl } = localStorage;

    delete localStorage.redirectUrl;

    const resp = await signin({
      email,
      password,
    });

    if (resp.success) {
      if (redirectUrl) {
        history.push(redirectUrl);
      } else {
        history.push(redirectAccordingToRole(resp.role));
      }
    }
  }

  render() {
    const { classes, theme } = this.props;
    const {
      emailBlurred,
      email,
      password,
      passwordBlurred,
    } = this.state;

    const { openSignup, openForgotPassword } = this.context;

    return (
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Helmet>
          <title>Signin</title>
          <meta name="Signin" content="Signin page" />
        </Helmet>
        <Header
          absolute
          color="transparent"
          rightLinks={<HeaderLinks />}
        />
        <Grid item xs={12}>
          <Zoom in>
            <div className={classes.zoomContainer}>
              <Card className={classes.card}>
                <form
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.signin();
                    }
                  }}
                >
                  <CardHeader color="primary">
                    <Grid container spacing={8} justify="center" alignItems="center">
                      <Grid item>
                      <img
                        alt="logo"
                        src={REACT_APP_LOGO}
                        height="60"
                      />
                      </Grid>
                      <Grid item>
                        <App
                          fullName
                          color={theme.palette.primary.color[500]}
                          name={'Reports'}
                          size={30}
                        />
                      </Grid>
                    </Grid>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={!isEmail(email) && emailBlurred}
                      success={isEmail(email) && emailBlurred}
                      inputProps={{
                        onChange: e => this.setState({ email: e.target.value }),
                        onBlur: () => this.setState({ emailBlurred: true }),
                        type: 'email',
                        endAdornment: !emailBlurred ? (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        ) : undefined,
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={(password === '') && passwordBlurred}
                      success={passwordBlurred && (password !== '')}
                      inputProps={{
                        onChange: e => this.setState({
                          password: e.target.value
                        }),
                        onBlur: () => this.setState({ passwordBlurred: true }),
                        type: 'password',
                        endAdornment: !passwordBlurred ? (
                          <InputAdornment position="end">
                            <LockOutline/>
                          </InputAdornment>
                        ) : undefined,
                      }}
                    />
                  </CardBody>
                  <CardFooter>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      className={classes.button}
                      onClick={this.signin.bind(this)}
                    >
                      Get started
                    </Button>
                    <br />
                  </CardFooter>
                </form>
                {
                  RESET_PASSWORD_ENABLED
                    && (
                      <div
                        className={classes.iForgotButton}
                        onClick={openForgotPassword}
                      >
                        <Typography variant="caption" gutterBottom>
                          I Forgot my Password
                        </Typography>
                      </div>
                    )
                }
              </Card>
              {
                SIGN_UP_ENABLED ?
                <Button
                  color="default"
                  size="sm"
                  className={classes.signupButton}
                  onClick={openSignup}
                >
                  Create an Account
                </Button> : []
              }
            </div>
          </Zoom>
        </Grid>
        <Copyright />
      </Grid>
    );
  }
}

export default withTheme()(withStyles(styles)(WrapperSigninPage));
