import {
  GET_GOAL_REVENUES,
} from 'constants/goalRevenues';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_GOAL_REVENUES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
