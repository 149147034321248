import {
  STORE_RESET_PASSWORD_TOKEN,
  SET_DARK_MODE,
  SET_CURRENT_WORKSPACE,
  SET_CURRENT_WAREHOUSE,
  SET_CURRENT_SUPERVISOR,
} from 'constants/app';

import {
  GET_DRIVE_TIME_STATUS,
} from 'constants/driveTime';

import {
  GET_EMPLOYEES_STATUS,
} from 'constants/employees';

import {
  GET_TASKS_STATUS,
} from 'constants/tasks';

import {
  GET_DRIVERS_STATUS,
} from 'constants/drivers';

import {
  GET_WAREHOUSE_STATUS,
} from 'constants/warehouses';

import {
  GET_APPOINTMENT_STATUS,
} from 'constants/appointments';

import {
  GET_CUSTOMER_STATUS,
} from 'constants/customers';

import {
  GET_HEALTH_STATUS,
} from 'constants/health';

import {
  DRIVETIME,
  EMPLOYEES,
  TASKS,
  DRIVERS,
  WAREHOUSES,
  APPOINTMENTS,
  CUSTOMERS,
  HEALTH,
} from 'constants/routes';

function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${cookiename}[^;]+`).exec(document.cookie);

  // Return everything after the equal sign, or an empty string if the cookie name not found
  const ret = decodeURIComponent(
    cookiestring
      ? cookiestring.toString().replace(/^[^=]+./, '')
      : '',
  );
  return ret;
}

export default (state = { status: {}, darkMode: getCookie('darkMode') === 'true' }, action) => {
  let newState;
  switch (action.type) {
    case STORE_RESET_PASSWORD_TOKEN:
      return { ...state, resetPasswordToken: action.token };
    case SET_CURRENT_WORKSPACE:
      return { ...state, currentWorkspace: action.token };
    case SET_CURRENT_SUPERVISOR:
      newState = Object.assign({}, state);
      delete newState.status;
      newState.status = {};
      newState.currentSupervisor = action.token;
      return newState;
    case SET_CURRENT_WAREHOUSE:
      newState = Object.assign({}, state);
      delete newState.status;
      newState.status = {};
      newState.currentWarehouse = action.token;
      return newState;
    case SET_DARK_MODE:
      return { ...state, darkMode: action.token };
    case `${GET_DRIVE_TIME_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[DRIVETIME] = action.payload;
      return newState;
    case `${GET_EMPLOYEES_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[EMPLOYEES] = action.payload;
      return newState;
    case `${GET_DRIVERS_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[DRIVERS] = action.payload;
      return newState;
    case `${GET_TASKS_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[TASKS] = action.payload;
      return newState;
    case `${GET_WAREHOUSE_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[WAREHOUSES] = action.payload;
      return newState;
    case `${GET_APPOINTMENT_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[APPOINTMENTS] = action.payload;
      return newState;
    case `${GET_CUSTOMER_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[CUSTOMERS] = action.payload;
      return newState;
    case `${GET_HEALTH_STATUS}_SUCCESS`:
      newState = Object.assign({}, state);
      newState.status[HEALTH] = action.payload;
      return newState;
    default:
      return state;
  }
};
