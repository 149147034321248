import {
  STORE_RESET_PASSWORD_TOKEN,
  SET_CURRENT_WORKSPACE,
  SET_DARK_MODE,
  SET_CURRENT_SUPERVISOR,
  SET_CURRENT_WAREHOUSE,
} from 'constants/app';

export const storeResetPasswordToken = token => async dispatch => dispatch({
  type: STORE_RESET_PASSWORD_TOKEN,
  token,
});

export const setDarkMode = token => async dispatch => dispatch({
  type: SET_DARK_MODE,
  token,
});

export const setCurrentWorkspace = token => async dispatch => dispatch({
  type: SET_CURRENT_WORKSPACE,
  token,
});

export const setCurrentSupervisor = token => async dispatch => dispatch({
  type: SET_CURRENT_SUPERVISOR,
  token,
});

export const setCurrentWarehouse = token => async dispatch => dispatch({
  type: SET_CURRENT_WAREHOUSE,
  token,
});
