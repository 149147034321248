export default theme => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  scrollButtons: {
    color: theme.palette.type === 'dark' ? 'white' : 'black',
  },
});
