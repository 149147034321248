import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const customers = `/${service}/customers`;

export function getCustomerStatus() {
  const url = `${customers}/status`;
  return {
    method: GET,
    url,
  };
}

export function getCustomersRatings() {
  const url = `${customers}/ratings`;
  return {
    method: GET,
    url,
  };
}
