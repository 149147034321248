import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { capitalize } from '@material-ui/core/utils/helpers';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Grow from '@material-ui/core/Grow';

// styles
import styles from './styles';

class Dashboards extends Component {
  static propTypes = {
    classes: PropTypes.object,
    dashboards: PropTypes.array,
    loading: PropTypes.bool,
  };

  state = {
    direction: 'up',
    open: false,
    hidden: false,
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  handleDirectionChange = (event, value) => {
    this.setState({
      direction: value,
    });
  };

  handleHiddenChange = (event, hidden) => {
    this.setState(state => ({
      hidden,
      // hidden implies !open
      open: hidden ? false : state.open,
    }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes, dashboards, loading } = this.props;

    const { direction, hidden, open } = this.state;

    const speedDialClassName = classNames(
      classes.speedDial,
      classes[`direction${capitalize(direction)}`],
    );

    return (
      <div>
        {
          !loading
            ? (
              <Grow in>
                <SpeedDial
                  ariaLabel="SpeedDial example"
                  color="secondary"
                  className={speedDialClassName}
                  classes={{
                    fab: classes.fab,
                  }}
                  hidden={hidden}
                  icon={<DashboardIcon />}
                  onBlur={this.handleClose}
                  onClick={this.handleClick}
                  onClose={this.handleClose}
                  onFocus={this.handleOpen}
                  onMouseEnter={this.handleOpen}
                  onMouseLeave={this.handleClose}
                  open={open}
                  direction={direction}
                >
                  {
                    dashboards.map(d => (
                      <SpeedDialAction
                        icon={(
                          <img
                            style={{ height: 20 }}
                            src={JSON.parse(d.config).widgetIcon}
                            alt="widget"
                          />
                        )}
                        tooltipTitle={d.name}
                        onClick={() => window.open(d.publicLink, '_blank')}
                        classes={{
                          button: classes.button,
                        }}
                      />
                    ))
                  }
                </SpeedDial>
              </Grow>
            )
            : <div />
        }
      </div>
    );
  }
}

export default withStyles(styles)(Dashboards);
