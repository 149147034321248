import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'v3/reports';
const supervisors = `/${service}/supervisors`;

export function getSupervisors() {
  const url = `${supervisors}`;
  return {
    method: GET,
    url,
  };
}

export function getSupervisorById(id) {
  const url = `${supervisors}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSupervisor(id, body) {
  const url = `${supervisors}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSupervisor(id) {
  const url = `${supervisors}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSupervisor(body) {
  const url = `${supervisors}`;
  return {
    method: POST,
    url,
    body,
  };
}
