export const routeNames = [
  'Default',
  'Signin',
  'Debug',
  'Workspace',
  'Drivetime',
  'Employees',
  'Tasks',
  'Drivers',
  'Warehouses',
  'Appointments',
  'Customers',
  'Health',
  'Sales',
  'DataStudio',
];
