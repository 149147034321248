import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import WrapperEmployeesPage from '../component/WrapperEmployeesPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getEmployeesClockIn: (...args) => dispatch(Actions.getEmployeesClockIn(...args)),
  getEmployeesAttendance: (...args) => dispatch(Actions.getEmployeesAttendance(...args)),
  getEmployeesOnTime: (...args) => dispatch(Actions.getEmployeesOnTime(...args)),
});
class EmployeesPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getEmployeesClockIn: PropTypes.func,
    getEmployeesAttendance: PropTypes.func,
    getEmployeesOnTime: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentSupervisor) this.refresh([1, 2, 3]);
  }

  componentWillReceiveProps(nextProps) {
    const nextSupervisor = nextProps.app.currentSupervisor;
    const currentSupervisor = this.props.app.currentSupervisor;
    if ((currentSupervisor === undefined) && (nextSupervisor !== undefined)) {
      setTimeout(() => this.refresh([1, 2, 3]), 500);
    } else if (currentSupervisor && nextSupervisor && (currentSupervisor.id !== nextSupervisor.id)) {
      setTimeout(() => this.refresh([1, 2, 3]), 500);
    }
  }

  initReport() {
    return (
      [
        {
          name: 'One Time',
        },
        {
          name: 'Clock In',
        },
        {
          name: 'Attendance',
        },
      ]
    );
  }

  refresh(key) {
    const { getEmployeesClockIn, getEmployeesAttendance, getEmployeesOnTime, app } = this.props;
    const { currentSupervisor } = app;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getEmployeesOnTime(currentSupervisor.id).then(r => this.resolve(0, r));
    }
    if (key.find(k => k === 2)) {
      getEmployeesClockIn(currentSupervisor.id).then(r => this.resolve(1, r));
    }
    if (key.find(k => k === 3)) {
      getEmployeesAttendance(currentSupervisor.id).then(r => this.resolve(2, r));
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperEmployeesPage
        history={history}
        location={location}
        reports={reports}
        key={app.currentSupervisor ? app.currentSupervisor.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeesPage);
