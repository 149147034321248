import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

// helpers
import { getThresholdColor } from 'helpers';

// styles
import styles from './styles';

class ReportTabs extends Component {
  static propTypes = {
    classes: PropTypes.object,
    reports: PropTypes.array,
    onChange: PropTypes.func,
    index: PropTypes.number,
    noStatus: PropTypes.bool,
  };

  render() {
    const {
      classes,
      reports,
      onChange,
      index,
      noStatus,
    } = this.props;

    return (
      <AppBar position="absolute" className="noScrollbar">
        <Tabs
          value={index}
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            root: classes.tabsRoot,
            scrollButtons: classes.scrollButtons,
            scroller: classes.scrollButtons,
          }}
        >
          {
            reports.map(r => (
              <Tab
                style={{ display: r.data && r.data.hidden ? 'none' : undefined }}
                label={(
                  <Grid container spacing={8} alignItems="center">
                    <Grid item>
                      <Typography variant="button">{r.name}</Typography>
                    </Grid>
                    {
                      noStatus !== true
                        ? (
                          <Grid item>
                            {
                              r.data
                                ? (
                                  <Chip
                                    label={r.data.dataStatus.length ? r.data.dataStatus : `${r.data.prefix}${r.data.glanceValue}${r.data.suffix}`}
                                    style={{
                                      background: getThresholdColor(
                                        r.data.thresholds,
                                        r.data.trigger,
                                        r.data.glanceValue,
                                      ),
                                      color: 'white',
                                    }}
                                  />
                                )
                                : (
                                  <Chip
                                    label={<CircularProgress size={20} style={{ color: 'black' }} />}
                                  />
                                )
                            }
                          </Grid>
                        ) : []
                    }
                  </Grid>
                )}
                key={r.name}
              />
            ))
          }
        </Tabs>
      </AppBar>
    );
  }
}

export default withStyles(styles)(ReportTabs);
