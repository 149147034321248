import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// component
import WrapperDrivetimePage from '../component/WrapperDrivetimePage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getDriveTimeAvg: (...args) => dispatch(Actions.getDriveTimeAvg(...args)),
  getDriveTimePercent: (...args) => dispatch(Actions.getDriveTimePercent(...args)),
});
class DrivetimePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    getDriveTimeAvg: PropTypes.func,
    getDriveTimePercent: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { app } = this.props;
    this.state = {
      reports: this.initReport(),
    };
    if (app.currentWarehouse) this.refresh([1, 2, 3, 4]);
  }

  initReport() {
    return (
      [
        {
          name: 'Avg Week',
        },
        {
          name: 'Avg Month',
        },
        {
          name: 'Percent Week',
        },
        {
          name: 'Percent Month',
        },
      ]
    );
  }

  componentWillReceiveProps(nextProps) {
    const nextWarehouse = nextProps.app.currentWarehouse;
    const currentWarehouse = this.props.app.currentWarehouse;
    if ((currentWarehouse === undefined) && (nextWarehouse !== undefined)) {
      setTimeout(() => this.refresh([1, 2, 3, 4]), 500);
    } else if (currentWarehouse && nextWarehouse && (currentWarehouse.id !== nextWarehouse.id)) {
      setTimeout(() => this.refresh([1, 2, 3, 4]), 500);
    }
  }

  refresh(key) {
    const { getDriveTimeAvg, getDriveTimePercent, app } = this.props;
    const { currentWarehouse } = app;
    this.setState({ reports: this.initReport() });

    if (key.find(k => k === 1)) {
      getDriveTimeAvg(currentWarehouse.id, 'week').then(r => this.resolve(0, r));
    }
    if (key.find(k => k === 2)) {
      getDriveTimeAvg(currentWarehouse.id, 'month').then(r => this.resolve(1, r));
    }
    if (key.find(k => k === 3)) {
      getDriveTimePercent(currentWarehouse.id, 'week').then(r => this.resolve(2, r));
    }
    if (key.find(k => k === 4)) {
      getDriveTimePercent(currentWarehouse.id, 'month').then(r => this.resolve(3, r));
    }
  }

  resolve(index, r) {
    const { reports } = this.state;
    if (r.success) {
      reports[index].data = r.payload;
      this.setState({ reports });
    }
  }

  render() {
    const {
      location,
      history,
      app,
    } = this.props;

    const { reports } = this.state;


    return (
      <WrapperDrivetimePage
        history={history}
        location={location}
        reports={reports}
        key={app.currentWarehouse ? app.currentWarehouse.id : undefined}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DrivetimePage);
