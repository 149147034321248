import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// helpers
import { formattedPrice } from 'helpers/thresholds';

// custom
import Progress from 'components/Progress';

// styles
import styles from './styles';

class UserProgress extends Component {
  static propTypes = {
    classes: PropTypes.object,
    report: PropTypes.object,
    index: PropTypes.number,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
  };

  state = {
    hover: false,
  }

  render() {
    const { report, index, onSelect, selected } = this.props;
    const { hover } = this.state;

    const colors = [
      '#9c27b0',
      '#3f51b5',
      '#00bcd4',
      '#4caf50',
      '#cddc39',
      '#ffc107',
    ];

    return (
      <Paper
        onClick={() => onSelect(report)}
        style={{
          padding: 15,
          textAlign: 'center',
          height: 'calc(100% - 32px)',
          position: 'relative',
          cursor: 'pointer',
          color: selected ? 'white' : undefined,
          background: selected ? colors[Number(index)] : undefined,
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        elevation={hover ? 10 : 2}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={32}
          style={{ height: 'calc(100% + 32px)' }}
        >
          <Grid item xs={6}>
            <Progress
              size={120}
              color={selected ? 'white' : colors[Number(index)]}
              value={report ? report.glanceValue / report.max * 100 : 0}
              variant="h5"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: selected ? 'white' : colors[Number(index)] }}>
              {report.title}
            </Typography>
            <Typography variant="h6" style={{ color: selected ? 'white' : colors[Number(index)] }}>
              {formattedPrice(Math.round(report.glanceValue))}
            </Typography>
            <Typography color={selected ? 'inherit' : 'textSecondary'}>
              {formattedPrice(Math.round(report.max))}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(UserProgress);
