import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// reports
import ReportTabs from 'components/ReportTabs';
import Progress from 'components/Progress';
import UserProgress from 'components/UserProgress';
import Table from 'components/Table';

// helpers
import { getUrlParams, getJsonFromUrl } from 'helpers';
import { formattedPrice } from 'helpers/thresholds';

// styles
import styles from './styles';

class WrapperTasksPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    reports: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const {
      classes,
      history,
      location,
      reports,
    } = this.props;

    const {
      index,
      selectedReport,
    } = this.state;

    const report = reports[index];
    const team = report && report.data ? report.data[0] : {};
    const _report = JSON.parse(JSON.stringify(report));
    if (_report && _report.data) _report.data.splice(0, 1);
    const trimedReports = _report.data;

    return (
      <div className={'page'}>
        <Helmet>
          <title>Sales Report</title>
          <meta name="Course" content="Course page" />
        </Helmet>
        <ReportTabs
          reports={reports}
          noStatus
          onChange={(e, v) => {
              const urlParams = getJsonFromUrl(location);
              urlParams.index = v;
              history.push({
                search: getUrlParams(urlParams),
              });
              this.setState({ index: v, selectedReport: undefined });
            }}
          index={index}
        />
        <div
          className={`${classes.background}`}
          style={{
            height: 'calc(100vh - 132px)',
            overflowY: 'scroll',
            padding: 20,
            paddingTop: 68,
            position: 'relative',
          }}
        >
          <div
            style={{
              maxWidth: 1200,
              margin: 'auto',
            }}
          >
            <Grid container>
              <Grid item style={{ width: 350 }}>
                <Paper style={{ padding: 15, textAlign: 'center' }}>
                  <Grid container alignItems="center" spacing={32}>
                    <Grid item xs={6}>
                      <Progress
                        size={150}
                        color="#2196f3"
                        value={team ? team.glanceValue / team.max * 100 : 0}
                        variant="h3"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h3">
                        {team.title}
                      </Typography>
                      <Typography color="textSecondary">
                        {team.description}
                      </Typography>
                      <Typography variant="h6" style={{ color: '#2196f3' }}>
                        {formattedPrice(Math.round(team.glanceValue))}
                      </Typography>
                      <Typography color="textSecondary">
                        {formattedPrice(Math.round(team.max))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={16}
              style={{ marginTop: 16 }}
              alignContent="stretch"
            >
              {
                trimedReports && trimedReports.map((d, i) => (
                  <Grid item key={d.title} xs={12} md={3}>
                    <UserProgress
                      report={d}
                      index={i}
                      selected={selectedReport ? selectedReport.id === d.id : false}
                      onSelect={(s) => this.setState({ selectedReport: s })}
                    />
                  </Grid>
                ))
              }
              {
                selectedReport
                  ? (
                    <Grid item xs={12}>
                      <Table
                        data={selectedReport ? selectedReport.values : []}
                        meta={[
                          {
                            path: 'id',
                            title: 'Load #',
                            numeric: false,
                          },
                          {
                            path: 'name',
                            title: 'Customer Name',
                            numeric: false,
                          },
                          {
                            path: 'description',
                            title: 'Customer Type',
                            numeric: false,
                          },
                          {
                            path: 'category',
                            title: 'Logistics Service',
                            numeric: false,
                          },
                          {
                            path: 'key',
                            title: 'Direction',
                            numeric: false,
                          },
                          {
                            path: 'date',
                            title: 'P/U Date',
                            numeric: false,
                            transform: t => moment(t).format('YYYY-MM-DD'),
                          },
                          {
                            path: 'value',
                            title: 'Base Amount',
                            numeric: false,
                            transform: t => formattedPrice(t),
                          },
                        ]}
                      />
                    </Grid>
                  ) : []
              }
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperTasksPage);