import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import WrapperDataStudioPage from '../component/WrapperDataStudioPage';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class DataStudioPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  render() {
    const {
      location,
      history,
    } = this.props;

    const {
      urlParams,
    } = this.state;

    return (
      <WrapperDataStudioPage
        history={history}
        location={location}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DataStudioPage);
