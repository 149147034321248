import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const employees = `/${service}/employees`;

export function getEmployeesStatus(supervisorID) {
  const url = `${employees}/status?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getEmployeesOnTime(supervisorID) {
  const url = `${employees}/ontime?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getEmployeesClockIn(supervisorID) {
  const url = `${employees}/clockin?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}

export function getEmployeesAttendance(supervisorID) {
  const url = `${employees}/attendance?supervisorID=${supervisorID}`;
  return {
    method: GET,
    url,
  };
}
