import { networkAction } from 'helpers/network/networkAction';

import {
  GET_DRIVE_TIME_AVG,
  GET_DRIVE_TIME_PERCENT,
  GET_DRIVE_TIME_STATUS,
} from 'constants/driveTime';

import * as Api from 'api';

export const getDriveTimeStatus = nodeID => async dispatch => networkAction(
  dispatch,
  GET_DRIVE_TIME_STATUS,
  Api.getDriveTimeStatus,
  [nodeID],
);

export const getDriveTimeAvg = (nodeID, span) => async dispatch => networkAction(
  dispatch,
  GET_DRIVE_TIME_AVG,
  Api.getDriveTimeAvg,
  [nodeID, span],
);

export const getDriveTimePercent = (nodeID, span) => async dispatch => networkAction(
  dispatch,
  GET_DRIVE_TIME_PERCENT,
  Api.getDriveTimePercent,
  [nodeID, span],
);
