import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const driveTime = `/${service}/driveTime`;

export function getDriveTimeAvg(nodeID, span) {
  const url = `${driveTime}/avg?nodeID=${nodeID}&span=${span || 'week'}`;
  return {
    method: GET,
    url,
  };
}

export function getDriveTimeStatus(nodeID) {
  const url = `${driveTime}/status?nodeID=${nodeID}`;
  return {
    method: GET,
    url,
  };
}

export function getDriveTimePercent(nodeID, span) {
  const url = `${driveTime}/percent?nodeID=${nodeID}&span=${span || 'week'}`;
  return {
    method: GET,
    url,
  };
}
