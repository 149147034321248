import { DEBUG } from 'constants/routes';

// import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import DebugPage from './container/DebugPage';

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: DEBUG,
    withSidebar: true,
    withAppBar: true,
    component: DebugPage,
  };
}
