import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// config
import {
  REACT_APP_API,
  REACT_APP_CLIENT_COLOR,
  REACT_APP_CLIENT_EMAIL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_CLIENT_WEBSITE,
} from "config";
import { routeNames } from "constants/routeNames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Routes from "routes";
import theme from "themes/theme";
// component
import WrapperRootPage from "../component/WrapperRootPage";

const mapStateToProps = (state) => ({
  errors: state.errors,
  app: state.app,
  user: state.user,
  userApps: state.userApps,
  supervisors: state.supervisors,
  warehouses: state.warehouses,
});

const mapDispatchToProps = (dispatch) => ({
  checkAccess: (...args) => dispatch(Actions.checkAccess(...args)),
  getSupervisors: (...args) => dispatch(Actions.getSupervisors(...args)),
  getWarehouses: (...args) => dispatch(Actions.getWarehouses(...args)),
  setCurrentSupervisor: (...args) =>
    dispatch(Actions.setCurrentSupervisor(...args)),
  setCurrentWarehouse: (...args) =>
    dispatch(Actions.setCurrentWarehouse(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  getUserApps: (...args) => dispatch(Actions.getUserApps(...args)),
  storeResetPasswordToken: (...args) =>
    dispatch(Actions.storeResetPasswordToken(...args)),
  signout: (...args) => dispatch(Actions.signout(...args)),
  requestResetPassword: (...args) =>
    dispatch(Actions.requestResetPassword(...args)),
  resetPassword: (...args) => dispatch(Actions.resetPassword(...args)),
  signup: (...args) => dispatch(Actions.signup(...args)),
  validateEmail: (...args) => dispatch(Actions.validateEmail(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
  getDriveTimeStatus: (...args) =>
    dispatch(Actions.getDriveTimeStatus(...args)),
  getEmployeesStatus: (...args) =>
    dispatch(Actions.getEmployeesStatus(...args)),
  getTasksStatus: (...args) => dispatch(Actions.getTasksStatus(...args)),
  getDriversStatus: (...args) => dispatch(Actions.getDriversStatus(...args)),
  getWarehousesStatus: (...args) =>
    dispatch(Actions.getWarehousesStatus(...args)),
  getAppointmentStatus: (...args) =>
    dispatch(Actions.getAppointmentStatus(...args)),
  getCustomerStatus: (...args) => dispatch(Actions.getCustomerStatus(...args)),
  getHealthStatus: (...args) => dispatch(Actions.getHealthStatus(...args)),
  getDashboards: (...args) => dispatch(Actions.getDashboards(...args)),
  stopImpersonate: (...args) => dispatch(Actions.stopImpersonate(...args)),
});

class RootPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    checkAccess: PropTypes.func,
    getCurrentUser: PropTypes.func,
    getUserApps: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,

    user: PropTypes.object,
    userApps: PropTypes.array,
    errors: PropTypes.object,
    app: PropTypes.object,

    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,

    setCurrentSupervisor: PropTypes.func,
    getSupervisors: PropTypes.func,
    supervisors: PropTypes.array,

    setCurrentWarehouse: PropTypes.func,
    getWarehouses: PropTypes.func,
    warehouses: PropTypes.array,

    stopImpersonate: PropTypes.func,

    getDriveTimeStatus: PropTypes.func,
    getEmployeesStatus: PropTypes.func,
    getTasksStatus: PropTypes.func,
    getDriversStatus: PropTypes.func,
    getWarehousesStatus: PropTypes.func,
    getAppointmentStatus: PropTypes.func,
    getCustomerStatus: PropTypes.func,
    getHealthStatus: PropTypes.func,
    getDashboards: PropTypes.func,
  };

  static childContextTypes = {
    checkAccess: PropTypes.func,
    userApps: PropTypes.array,
  };

  getChildContext() {
    const { checkAccess, userApps } = this.props;
    return {
      checkAccess,
      userApps,
    };
  }

  componentWillMount() {
    console.log(
      `%cDeveloped by ${REACT_APP_CLIENT_NAME}`,
      `color: ${REACT_APP_CLIENT_COLOR}; font-size: 28px`
    );
    console.log(
      "%cin Los Angeles, California",
      "color: #607d8b; font-size: 18px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_EMAIL}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_WEBSITE}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%cRunning on ${REACT_APP_API} API`,
      "color: #607d8b; font-size: 12px"
    );
  }

  getRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        if (route.onEnter()) {
          routes.unshift(route);
        }
      }
    }

    return routes;
  }

  getAllRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        routes.unshift(route);
      }
    }

    return routes;
  }

  init() {
    const { getSupervisors, getUserApps, getWarehouses } = this.props;
    return Promise.all([getSupervisors(), getWarehouses(), getUserApps()]);
  }

  render() {
    const {
      app,
      user,
      getCurrentUser,
      checkAccess,
      signout,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      storeResetPasswordToken,
      setCurrentWarehouse,
      setCurrentSupervisor,
      resetPassword,
      setDarkMode,
      userApps,
      stopImpersonate,
      history,
      supervisors,
      warehouses,
      getDriveTimeStatus,
      getEmployeesStatus,
      getWarehousesStatus,
      getTasksStatus,
      getDriversStatus,
      getAppointmentStatus,
      getCustomerStatus,
      getHealthStatus,
      getDashboards,
    } = this.props;

    const muiTheme = createMuiTheme({
      palette: { ...theme, type: this.props.app.darkMode ? "dark" : "light" },
      typography: {
        useNextVariants: true,
      },
    });

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Router>
          <WrapperRootPage
            getDriveTimeStatus={getDriveTimeStatus}
            getWarehousesStatus={getWarehousesStatus}
            getCustomerStatus={getCustomerStatus}
            getHealthStatus={getHealthStatus}
            getAppointmentStatus={getAppointmentStatus}
            getEmployeesStatus={getEmployeesStatus}
            getDriversStatus={getDriversStatus}
            getTasksStatus={getTasksStatus}
            getDashboards={getDashboards}
            supervisors={supervisors}
            warehouses={warehouses}
            setCurrentSupervisor={setCurrentSupervisor}
            setCurrentWarehouse={setCurrentWarehouse}
            stopImpersonate={stopImpersonate}
            setDarkMode={setDarkMode}
            app={app}
            checkAccess={checkAccess}
            getCurrentUser={getCurrentUser}
            storeResetPasswordToken={storeResetPasswordToken}
            signout={signout}
            requestResetPassword={requestResetPassword}
            resetPassword={resetPassword}
            validateEmail={validateEmail}
            signup={signup}
            user={user}
            userApps={userApps}
            errors={errors}
            routes={this.getRoutes()}
            allRoutes={this.getAllRoutes()}
            init={this.init.bind(this)}
            history={history}
          />
        </Router>
      </MuiThemeProvider>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootPage);
