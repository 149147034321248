import { networkAction } from 'helpers/network/networkAction';

import {
  GET_EMPLOYEES_STATUS,
  GET_EMPLOYEES_ON_TIME,
  GET_EMPLOYEES_CLOCK_IN,
  GET_EMPLOYEES_ATTENDANCE,
} from 'constants/employees';

import * as Api from 'api';

export const getEmployeesStatus = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_EMPLOYEES_STATUS,
  Api.getEmployeesStatus,
  [supervisorID],
);

export const getEmployeesOnTime = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_EMPLOYEES_ON_TIME,
  Api.getEmployeesOnTime,
  [supervisorID],
);

export const getEmployeesClockIn = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_EMPLOYEES_CLOCK_IN,
  Api.getEmployeesClockIn,
  [supervisorID],
);

export const getEmployeesAttendance = supervisorID => async dispatch => networkAction(
  dispatch,
  GET_EMPLOYEES_ATTENDANCE,
  Api.getEmployeesAttendance,
  [supervisorID],
);
