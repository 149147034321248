import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const health = `/${service}/health`;

export function getHealthStatus() {
  const url = `${health}/status`;
  return {
    method: GET,
    url,
  };
}

export function getHealthServices() {
  const url = `${health}/services`;
  return {
    method: GET,
    url,
  };
}
