import { DRIVERS } from 'constants/routes';
import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import DriversPage from './container/DriversPage';

export default function getSettingsRoute(userAuth) {
  const { account, reports } = serviceRoles;
  const requireAuth = {
    [account.name]: [
      account.roles.superadmin,
    ],
    [reports.name]: [
      reports.roles.reporter,
    ],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: DRIVERS,
    withSidebar: true,
    withAppBar: true,
    component: DriversPage,
  };
}
