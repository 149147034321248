export default theme => ({
  background: {
    background: theme.palette.background.default,
  },
  container: {
    padding: 20,
    background: theme.palette.background.default,
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  floatingActionButton: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
});
