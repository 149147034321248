import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const appointments = `/${service}/appointments`;

export function getAppointmentStatus(nodeID) {
  const url = `${appointments}/status?nodeID=${nodeID}`;
  return {
    method: GET,
    url,
  };
}

export function getWarehousesAppointmentIssuesGraph(nodeID, span) {
  const url = `${appointments}/Issues?span=${span}&nodeID=${nodeID}`;
  return {
    method: GET,
    url,
  };
}

export function getAppointmentsIssues(nodeID, span) {
  const url = `${appointments}/Issues?nodeID=${nodeID}&span=${span}`;
  return {
    method: GET,
    url,
  };
}
