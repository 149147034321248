import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

// styles
import styles from './styles';

class Progress extends Component {
  static propTypes = {
    classes: PropTypes.object,
    size: PropTypes.number,
    color: PropTypes.string,
    value: PropTypes.number,
    variant: PropTypes.string,
  };

  render() {
    const { classes, size, color, value, variant } = this.props;

    let roundedValue = Math.round(value);
    if (isNaN(roundedValue)) roundedValue = 0;

    return (
      <div
        style={{
          position: 'relative',
          height: size,
          color,
        }}
      >
        <CircularProgress
          size={size}
          variant="determinate"
          value={100}
          style={{
            color: 'rgba(155,155,155,0.3)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <CircularProgress
          size={size}
          variant="static"
          value={roundedValue}
          style={{
            color,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{
            position: 'absolute',
            height: size,
            width: size,
          }}
        >
          <Grid item>
            <Typography color="inherit" variant={variant}>
              {roundedValue} <span style={{ fontSize: 12 }}>%</span>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Progress);
