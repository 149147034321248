import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// reports
import ReportTabs from 'components/ReportTabs';
import Report from 'components/Report';

// helpers
import { getUrlParams, getJsonFromUrl } from 'helpers';

// styles
import styles from './styles';

class WrapperCustomerPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    reports: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const {
      classes,
      history,
      location,
      reports,
    } = this.props;

    const {
      index,
    } = this.state;

    return (
      <div className={'page'}>
        <Helmet>
          <title>Drive Time Report</title>
          <meta name="Course" content="Course page" />
        </Helmet>
        <ReportTabs
          reports={reports}
          onChange={(e, v) => {
              const urlParams = getJsonFromUrl(location);
              urlParams.index = v;
              history.push({
                search: getUrlParams(urlParams),
              });
              this.setState({ index: v });
            }}
          index={index}
        />
        <div
          className={`${classes.background}`}
          style={{
            height: 'calc(100vh - 132px)',
            overflowY: 'scroll',
            padding: 20,
            paddingTop: 68,
            position: 'relative',
          }}
        >
          <div className={classes.container}>
            {
              reports[index].data && reports[index].data.values.length
                ? (
                  <Report
                    report={reports[index].data}
                  />
                )
                : (
                  <Grid container style={{ height: 'calc(100vh - 136px)' }} justify="center" alignItems="center">
                    {
                      reports[index].data && reports[index].data.values.length === 0
                        ? (
                          <Grid item style={{ textAlign: 'center' }}>
                            <Typography>
                              No Data
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item style={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography>
                              Loading Report
                            </Typography>
                          </Grid>
                        )
                    }
                  </Grid>
                )
            }
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperCustomerPage);