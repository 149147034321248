import {
  GET,
} from 'constants/methods';

const service = 'v3/reports';
const warehouses = `/${service}/warehouses`;

export function getWarehouses() {
  const url = `${warehouses}`;
  return {
    method: GET,
    url,
  };
}

export function getWarehousesStatus(nodeID) {
  const url = `${warehouses}/status?nodeID=${nodeID}`;
  return {
    method: GET,
    url,
  };
}

export function getWarehouseCargoConsolidation(nodeID) {
  const url = `${warehouses}/cargoconsolidation?nodeID=${nodeID}`;
  return {
    method: GET,
    url,
  };
}

export function getWarehouseCargoConsolidationGraph() {
  const url = `${warehouses}/cargoconsolidationgraph`;
  return {
    method: GET,
    url,
  };
}
